import firebase from 'firebase';
import 'firebase/storage';
import 'firebase/firestore';
import 'firebase/analytics';

export const firebaseConfig = {
  apiKey: 'AIzaSyCaxZTfRiVGdJb6naCTmvmtCsE4rsmR2D0',
  authDomain: 'voicecards-691cb.firebaseapp.com',
  databaseURL: 'https://voicecards-691cb.firebaseio.com',
  projectId: 'voicecards-691cb',
  storageBucket: 'voicecards-691cb.appspot.com',
  messagingSenderId: '148685279777',
  appId: '1:148685279777:web:ba3abd009238a5c1128960',
  measurementId: 'G-FWP03S98EE',
};
//
const voiceApp = firebase.initializeApp(firebaseConfig);

const storage = voiceApp.storage();

const analytics = () => {
  voiceApp.analytics();
};

console.log('firebase init');

const db = voiceApp.firestore();
export { storage, db, voiceApp, analytics as default };
