import {
  BLUE_CIRCLE_PATH,
  DEDOC_PATH,
  DEER_PATH,
  GINGERBREAD_PATH,
  GO_SAKE,
  happyBirthday_playful_1_path,
  INSULIN_PUMP_PATH,
  LETS_PARTY_PATH,
  LOVE_CHAT_PATH,
  MONSTER_BLUE_CIRCLE_PATH,
  PARTY_PATH,
  PENGUIN_PATH,
  RING_LOVE_PATH,
  SANTA_PATH,
  SLIM_RING_PATH,
  SNOW_FLAKE,
  SNOW_FLAKE_2,
  SNOW_FLAKE_3,
  THANK_YOU_PATH,
  TREE_PATH,
  TREE_PRESENTS_PATH,
  WEDDING_PATH,
} from '../visuals/icons/icons';
import img from '../assets/DCB_logo.png';
import bsl_logo from '../assets/BSLWeihnachten2021.png';
import circle_svg from '../assets/circle.svg';
import mdi_logo from '../assets/mdi_logo_xmas.svg';

const { detect } = require('detect-browser');
// Initialize an array to store line data
const lineBuffer = [];

// Function to add line data to the buffer
const addLineToBuffer = (x1, y1, x2, y2) => {
  lineBuffer.push({ x1, y1, x2, y2 });
};
const browser = detect();
let ctx, x_end, y_end, bar_height;

// constants
const scale = 1;

/*const width = 350 * scale;
const height = 400 * scale;*/

const bar_width = 3;
const radius = 128;
let center_x;
let center_y;
let browserAmp = 1;

let windowWidth, windowHeight;

let imageObj1 = new Image();
imageObj1.src = img;

let bsl = new Image();
bsl.src = bsl_logo;

let circle = new Image();
circle.src = circle_svg;

let mdi = new Image();
mdi.src = mdi_logo;

/*let tree_present = new Image();
tree_present.src = TREE_PRESENT_ICON;*/

let image_logo;

const card =
  'M339,463H11A11,11,0,0,1,0,452V11A11,11,0,0,1,11,0H339a11,11,0,0,1,11,11V452a11,11,0,0,1-11,11ZM174.5,43a130.71,130.71,0,0,0-26.1,2.63A129.44,129.44,0,0,0,47.63,146.4a130.82,130.82,0,0,0,0,52.2A129.44,129.44,0,0,0,148.4,299.37a130.82,130.82,0,0,0,52.2,0A129.44,129.44,0,0,0,301.37,198.6a130.82,130.82,0,0,0,0-52.2A129.44,129.44,0,0,0,200.6,45.63,130.79,130.79,0,0,0,174.5,43Z';
const CARD_PATH = new Path2D(card);

const card_02 =
  'M346.78,3.22A10.92,10.92,0,0,0,339,0H11A11,11,0,0,0,0,11V452a11,11,0,0,0,11,11H339a11,11,0,0,0,11-11V11A10.92,10.92,0,0,0,346.78,3.22ZM301.37,198.6a128.72,128.72,0,0,1-19.48,46.3,132.49,132.49,0,0,1-9.25,12.1c-36.73-15-85.55-15.54-93.28-15.54-8.6,0-63.31.17-97.69,21.32a129.74,129.74,0,0,1-34-116.38,128.72,128.72,0,0,1,19.48-46.3A129.74,129.74,0,0,1,200.6,45.63a128.72,128.72,0,0,1,46.3,19.48A129.74,129.74,0,0,1,301.37,198.6Z';
const CARD_PATH_2 = new Path2D(card_02);

const DEFAULT_ICON =
  'M49,0A49,49,0,1,0,98,49,49,49,0,0,0,49,0Zm1.24,42.17a1.76,1.76,0,0,1-1.35,1.63,25.68,25.68,0,0,1-8.34.33c.49,1.9,2.17,3.31,4.88,4.06a3.1,3.1,0,0,1,1.92,1.55,3.18,3.18,0,0,1,.18,2.51l-.35.93a7.51,7.51,0,0,0-.39,2,6.87,6.87,0,0,1-6.06,8.44c-1.06.1-2.13.15-3.22.15a39.13,39.13,0,0,1-6.65-.58h0c-.82-.15-1.61-.33-2.39-.52L27.39,74.37A1.75,1.75,0,0,1,25.64,76h-.17a1.77,1.77,0,0,1-1.59-1.91L25,61.61a24,24,0,0,1-4.43-2.33h0a15.29,15.29,0,0,1-5.2-5.4,1.76,1.76,0,0,1,3.11-1.63,11.92,11.92,0,0,0,4.09,4.11,22.72,22.72,0,0,0,8.88,3.41,34.63,34.63,0,0,0,8.92.46A3.38,3.38,0,0,0,43.35,56a1.55,1.55,0,0,1,0-.21,9.83,9.83,0,0,1,.55-3.73l.21-.61c-5.2-1.58-6.64-4.92-7-6.8a3.37,3.37,0,0,1,3.74-4,22.94,22.94,0,0,0,6,0L47,35.68A1.78,1.78,0,0,1,48.45,34a7.39,7.39,0,0,0,3.84-1.69A1.14,1.14,0,0,0,52.53,31a3,3,0,0,0-.92-.78A14,14,0,0,1,45.19,17.8a27.67,27.67,0,0,0,0-2.88,1.76,1.76,0,1,1,3.51-.24,25.8,25.8,0,0,1,0,3.29,10.53,10.53,0,0,0,4.87,9.31,5.66,5.66,0,0,1,2.15,2.19,4.62,4.62,0,0,1-1,5.43,9.92,9.92,0,0,1-4.22,2.27ZM56.16,55.7a1.75,1.75,0,0,1-1.34.25,1.76,1.76,0,0,1-1.34-2.12,1.8,1.8,0,0,1,.79-1.1h0A5.19,5.19,0,0,0,56,45.88a1.76,1.76,0,1,1,3.09-1.69A8.72,8.72,0,0,1,56.16,55.7Zm6.57,6.71a1.76,1.76,0,0,1-1.1.39h0a1.76,1.76,0,0,1-1.14-3.15,14.5,14.5,0,0,0,4.34-16.8,1.76,1.76,0,0,1,3.25-1.35,18,18,0,0,1-5.35,20.91Zm15.15-7.25a27.27,27.27,0,0,1-8.75,14,1.73,1.73,0,0,1-1.31.48,1.75,1.75,0,0,1-1.64-2,1.73,1.73,0,0,1,.7-1.2A23.75,23.75,0,0,0,73.71,40a1.76,1.76,0,0,1,1-2.27h0a1.76,1.76,0,0,1,2.27,1A27.29,27.29,0,0,1,77.88,55.16Z';
const DEFAULT_PATH = new Path2D(DEFAULT_ICON);

let svgImage;
let cardText;

let _canvas;

export const animationLooper3 = {
  createCanvas: (canvas, record, height, width) => {
    center_x = width / (2 * scale);
    center_y = height / (2.35 * scale);

    windowWidth = width;
    windowHeight = height;
    console.log('width', width);
    _canvas = canvas;
    if (canvas) {
      canvas.width = width;
      canvas.height = height;
      ctx = canvas.getContext('2d');
      ctx.scale(scale, scale);
      // handle the case where we don't detect the browser

      if (record) {
        switch (browser && browser.name) {
          case 'chrome':
            browserAmp = 0.9;
            console.log('Chrome');
            break;
          case 'firefox':
            console.log('firefox');
            break;
          case 'safari':
            console.log('safari');
            browserAmp = 1.8;
            break;
          case 'edge':
            console.log('edge');
            break;

          default:
            browserAmp = 1;
            console.log('not detected' + browser.name);
            break;
        }
      } else {
        browserAmp = 1;
        console.log('not record' + browser.name);
      }
    }
  },
  draw: (frequency_array, rotate, background, image, text, font) => {

    //todo:// save frequency array?
    let xIcon = 0;
    let yIcon = 120;
    let xCorrection = 0;
    ctx.lineWidth = bar_width;

    //let xIcon = 125;
    //  let yIcon = 120;

    let logoWidth, logoHeight;
    ctx.fillStyle = background;
    ctx.strokeStyle = '#ffffff';

    if (image === 1) {
      svgImage = TREE_PATH;
      logoWidth = 85;

      xIcon = 135;
    } else if (image === 2) {
      svgImage = SANTA_PATH;
      logoWidth = 90;

      xIcon = 135;
    } else if (image === 3) {
      svgImage = PARTY_PATH;
      xIcon = 125;
      logoWidth = 96;
    } else if (image === 4) {
      svgImage = BLUE_CIRCLE_PATH;
      xIcon = 75;
      yIcon = 75;
      logoWidth = 200;
    } else if (image === 5) {
      svgImage = MONSTER_BLUE_CIRCLE_PATH;
      logoWidth = 125;
    } else if (image === 6) {
      svgImage = INSULIN_PUMP_PATH;
      xIcon = 75;
      yIcon = 90;
      logoWidth = 195;
    } else if (image === 7) {
      svgImage = DEDOC_PATH;
      xIcon = 70;
      yIcon = 105;
      logoWidth = 172;
    } else if (image === 8) {
      svgImage = WEDDING_PATH;
      xIcon = 100;
      yIcon = 100;
      logoWidth = 150;
    } else if (image === 9) {
      svgImage = LOVE_CHAT_PATH;
      xIcon = 100;
      yIcon = 90;
      logoWidth = 150;
    } else if (image === 10) {
      svgImage = SLIM_RING_PATH;
      xIcon = 85;
      yIcon = 110;
      logoWidth = 180;
    } else if (image === 11) {
      svgImage = RING_LOVE_PATH;
      xIcon = 110;
      yIcon = 90;
      logoWidth = 135;
    } else if (image === 12) {
      svgImage = null;
      image_logo = imageObj1;
      //   xIcon = 45;
      yIcon = 45;
      xCorrection = -2;

      logoWidth = 254;
      logoHeight = 254;
    } else if (image === 13) {
      svgImage = null;
      image_logo = bsl;
      xIcon = 78;
      xCorrection = -10;
      yIcon = 90;
      logoWidth = 170;
      logoHeight = 170;
    } else if (image === 14) {
      svgImage = TREE_PRESENTS_PATH;
      xIcon = 81;
      yIcon = 93;
      logoWidth = 192;
    } else if (image === 15) {
      svgImage = PENGUIN_PATH;
      xIcon = 81;
      yIcon = 91;
      logoWidth = 192;
    } else if (image === 16) {
      svgImage = happyBirthday_playful_1_path;
      xIcon = 75;
      yIcon = 75;
      logoWidth = 192;
    } else if (image === 17) {
      svgImage = DEER_PATH;
      xIcon = 85;
      yIcon = 75;
      logoWidth = 142;
    } else if (image === 18) {
      svgImage = GINGERBREAD_PATH;
      xIcon = 125;
      yIcon = 55;
      logoWidth = 86;
    } else if (image === 19) {
      svgImage = LETS_PARTY_PATH;
      xIcon = 85;
      yIcon = 85;
      logoWidth = 184;
    } else if (image === 20) {
      svgImage = THANK_YOU_PATH;
      xIcon = 60;
      yIcon = 90;
      logoWidth = 223;
    } else if (image === 22) {
      svgImage = null;
      image_logo = mdi;
      xIcon = 78;
      // xCorrection = -10;
      yIcon = 133;
      logoWidth = 134.7;
      logoHeight = 167.5;
    } else if (image === 23) {
      svgImage = SNOW_FLAKE;
      yIcon = 48;
      logoWidth = 247.2;
    } else if (image === 24) {
      svgImage = SNOW_FLAKE_2;
      yIcon = 50;
      logoWidth = 242.4;
    } else if (image === 25) {
      svgImage = SNOW_FLAKE_3;
      yIcon = 46;
      logoWidth = 251.8;
    } else {
      xIcon = 60;
      yIcon = 120;
      svgImage = DEFAULT_PATH;
      logoWidth = 98;
    }

    if (text === undefined) {
      cardText = '';
    } else {
      cardText = text;
    }

    ctx.clearRect(0, 0, windowWidth, windowHeight);

    if (rotate === 0) {
      lineBuffer.length = 0; // Clear existing line buffer

    }
// Draw the background and other static elements
    if (windowWidth > 350) {
      ctx.fillStyle = '#fff';
      ctx.fillRect(0, 0, windowWidth, windowHeight);
    } else {
      // Draw a specific background shape
      ctx.fill(CARD_PATH);
    }

// Draw other content (icons, images, text, etc.)
    ctx.translate(windowWidth / 2 - logoWidth / 2, yIcon);
    if (svgImage) {
      ctx.fillStyle = background;
      ctx.fill(svgImage);
    } else {
      ctx.drawImage(image_logo, xCorrection, 0, logoWidth, logoHeight);
    }
    ctx.translate(-windowWidth / 2 + logoWidth / 2, -yIcon);


    for (var i = 0; i < 5; i++) {
      bar_height = Math.pow(frequency_array[i] - 124, browserAmp);
      let amp = bar_height;
      amp = Math.max(amp, 2);

      const x = radius;
      const y = 0;
      x_end = radius + amp;
      y_end = 0;

      // Calculate the rotated positions
      const rotated_x = x * Math.cos(rotate) - y * Math.sin(rotate);
      const rotated_y = x * Math.sin(rotate) + y * Math.cos(rotate);
      const rotated_x_end = x_end * Math.cos(rotate) - y_end * Math.sin(rotate);
      const rotated_y_end = x_end * Math.sin(rotate) + y_end * Math.cos(rotate);

      // Add line data to the buffer
      addLineToBuffer(rotated_x, rotated_y, rotated_x_end, rotated_y_end);
    }

    // text
    ctx.font = font || '25px Caveat';
    ctx.textAlign = 'center';
    ctx.fillStyle = '#fff';
    ctx.textBaseline = 'middle';
    var x = windowWidth / 2;
    var y = windowHeight - windowHeight / 10;
    var lineheight = 22;
    var lines = cardText.split('\n');

    for (var i = 0; i < lines.length; i++) ctx.fillText(lines[i], x, y + i * lineheight);

// Draw the buffered lines with rotation
    ctx.save();
    ctx.translate(center_x, center_y);
    //  ctx.rotate(rotate);
    ctx.lineCap = 'round';

    for (const line of lineBuffer) {
      ctx.beginPath();
      ctx.moveTo(line.x1, line.y1);
      ctx.lineTo(line.x2, line.y2);
      ctx.stroke();
    }

    ctx.restore();

  },
};
