import React, { useEffect, useState } from 'react';
import { dbUtils } from '../../utils/dbService';
import { Button as GrommetButton } from 'grommet/components/Button';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import rits from '../../assets/rits.jpg';

const Gaestebuch = () => {
  const [sounds, setSounds] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    dbUtils.getTopicData('merrit_amrit').then(data => {
      console.log(data);
      setSounds(data);
    });
  }, []);

  return (
    <Box p={3}>
      <img src={rits} width={'100%'} />
      <Box marginBottom={6} align={'center'}>
        {' '}
        <Typography variant={'h5'}>
          Hochzeitsgästebuch <br /> Merrit & Amrit
        </Typography>
      </Box>

      {sounds &&
        sounds.map((item, index) => {
          console.log(item.data());
          const soundUrl = `https://firebasestorage.googleapis.com/v0/b/voicecards-691cb.appspot.com/o/voices%2F${
            item.data().userId
          }%2Fvoicecard_${item.data().soundId}_output.mp3?alt=media`;

          return (
            <div key={index} className={classes.root}>
              <div>
                <Typography>
                  {index + 1} – {item.data().text} –
                </Typography>
              </div>
              <GrommetButton
                margin={'small'}
                primary
                label="Karte anschauen"
                //onClick={() => }
                href={'/p/' + item.data().soundId}
                value="Start"
              />
              <audio controls className={classes.audio}>
                <source src={soundUrl} type="audio/mpeg" />
                Your browser does not support the audio element.
              </audio>

              {/*
              <Typography style={{width: 100}}>{soundUrl}</Typography>
*/}
            </div>
          );
        })}
    </Box>
  );
};

export default Gaestebuch;

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: 32,
  },
  audio: {
    display: 'inline',
  },
}));
