import { storage } from '../../../firebase';

const imageUpload = (blob, id, userId) => {
  const uploadTask = storage.ref(`images/${userId}/voicecard_${id}.png`).put(blob);
  uploadTask.on(
    'state_changed',
    snapshot => {
      const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
      console.log(progress);
    },
    error => {
      console.log(error);
    },
    () => {
      storage
        .ref('images/' + userId)
        .child(`voicecard_${id}.png`)
        .getDownloadURL()
        .then(url => {
          console.log(url);
        });
    }
  );
};

export default imageUpload;
