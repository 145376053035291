import React, { createRef, useEffect, useRef } from 'react';
import createAudioContext from 'ios-safe-audio-context';
import { Box } from 'grommet';
import { defaultColor } from '../utils/color';
import { animationLooper3 } from '../utils/animationLooper3';
import useWindowDimensions from '../hooks/windowWidth';

const RealTimeAudioCanvas = props => {
  let audioContext = useRef();
  let canvas = createRef();
  let rafId = useRef();
  let frequency_array = useRef();
  let analyser = useRef();
  // Changing Variables
  let rotate = 0;
  let source = useRef();
  const { height, width } = useWindowDimensions();

  useEffect(() => {
    try {
      audioContext.current = createAudioContext();
      source.current = audioContext.current.createMediaStreamSource(props.audio);
      analyser.current = audioContext.current.createAnalyser();
      source.current.connect(analyser.current);
      frequency_array.current = new Uint8Array(analyser.current.frequencyBinCount);
      animationLooper3.createCanvas(canvas.current, true, 400, 350);
      tick();
    } catch (e) {
      console.log(e);
    }
    return () => {
      cancelAnimationFrame(rafId.current);
      source.current.disconnect();
      analyser.current.disconnect();
    };
  }, []);


  const tick = () => {
    updateFrequencyData();
    drawAnimation();
    updateRotateAngle();
    rafId.current = requestAnimationFrame(tick);

  };

  const updateFrequencyData = () => {
    analyser.current.getByteTimeDomainData(frequency_array.current);
  };
  const updateRotateAngle = () => {
    rotate += ((2 * Math.PI) / 180) ;
  };

  const drawAnimation = () => {
    animationLooper3.draw(frequency_array.current, rotate, defaultColor, 0, '', '25px Caveat');
  };



  return (
    <Box>
      <canvas style={{ margin: 'auto', width: 'fit-content' }} ref={canvas} />
    </Box>
  );
};

export default RealTimeAudioCanvas;
