import { storage } from '../../../firebase';
import { dbUtils } from '../../../utils/dbService';
import { detect } from 'detect-browser';
import generateQR from '../Customizer/generateQR';

const handleUpload = (
  url,
  id,
  originUrl,
  getProgress,
  userId,
  background,
  text,
  topic,
  svgImage,
  font,
  proUrl,
  proMail
) => {
  const browser = detect();
  const date = new Date().toLocaleDateString();
  var metadata = {
    contentType: url.type,
  };

  const uploadTask = storage.ref(`voices/${userId}/voicecard_${id}.mp4`).put(url, metadata);
  uploadTask.on(
    'state_changed',
    snapshot => {
      const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
      getProgress(progress);
    },
    error => {
      console.log(error);
    },
    () => {
      storage
        .ref('voices/' + userId)
        .child(`voicecard_${id}.mp4`)
        .getDownloadURL()
        .then(url => {
          console.log('Download' + url);
          //TODO: Chek if mp3 is converted

          let data = {
            soundId: id,
            browser: browser && browser.name,
            color: background,
            svgImage: svgImage,
            text: text,
            topic: topic,
            backgroundSound: topic,
            userId: userId,
            timestamp: new Date(),
            localDateString: date,
            font: font,
            picturePath: null,
            proUrl: proUrl,
            proMail: proMail,
          };

          dbUtils
            .create(id, data)
            .then(() => {
              //   console.log("Created new item successfully!");
              console.log('id', id);
            })
            .catch(e => {
              console.log(e);
            });

          generateQR(id, originUrl);
        });
    }
  );
};

export default handleUpload;
