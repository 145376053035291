import React from 'react';
import { Button, createStyles, Link, makeStyles, Typography } from '@material-ui/core';
import Page from '../../common/Page';
import { useTranslation } from 'react-i18next';

const NoMicAccess = () => {
  const { t, i18n } = useTranslation();

  const classes = useStyles();

  return (
    <Page>
      <div className={classes.container}>
        <Typography variant={'h5'}>{t('audio_warning.title')}</Typography>
        <br />
        <Typography variant={'subtitle1'}>
          {t('audio_warning.allow')}
          <strong>{t('audio_warning.allow_2')}</strong>
        </Typography>
        <br />
        <a href={'https://induktiv.cc/voicecard/redirect.php'} rel={'norefferer noopener'}>
          {t('audio_warning.description')}
        </a>
        <br />
        <br />
        <Button
          component={Link}
          style={{ textDecoration: 'none' }}
          href={'https://induktiv.cc/voicecard/redirect.php'}
          rel={'norefferer noopener'}
        >
          Im Browser öffnen
        </Button>
      </div>
    </Page>
  );
};

export default NoMicAccess;

const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      width: 300,
      opacity: 1,
      animationName: '$fadeInOpacity',
      animationIterationCount: 1,
      animationTimingFunction: 'ease-in',
      animationDuration: '1s',
    },
    '@keyframes fadeInOpacity': {
      '0%': {
        opacity: 0,
      },
      '100%': {
        opacity: 1,
      },
    },
  })
);
