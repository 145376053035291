import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import { AcUnit, Cake } from '@material-ui/icons';
import { BLUE_CIRCLE_ICON, DEDOC_ICON, WEDDING_ICON } from '../../../visuals/icons/icons';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}));

const DesignTopics = props => {
  const classes = useStyles();
  const search = useLocation().search;
  const topic = new URLSearchParams(search).get('t');

  const [chipData, setChipData] = React.useState([
    { key: 0, label: 'Weihnachten' },
    { key: 1, label: 'Geburtstag' },
    { key: 2, label: 'Weltdiabetestag' },
    { key: 3, label: 'dedoc' },
    { key: 4, label: 'Hochzeit' },
  ]);

  const handleTopicSelect = selectedTopic => {
    props.handleTopicId(selectedTopic);

    return setChipData(prevData => [
      ...prevData.map(data => {
        return { key: data.key, label: data.label, selected: data.key === selectedTopic };
      }),
    ]);
  };

  return (
    <Paper component="ul" className={classes.root}>
      {chipData
        .filter(data => (topic !== 'dedoc' ? data.key !== 3 : data))
        .map(data => {
          let icon;

          if (data.label === 'Weihnachten') {
            icon = <AcUnit />;
          }
          if (data.label === 'Geburtstag') {
            icon = <Cake />;
          }
          if (data.label === 'Weltdiabetestag') {
            icon = <BLUE_CIRCLE_ICON />;
          }
          if (data.label === 'Hochzeit') {
            icon = <WEDDING_ICON />;
          }
          if (data.label === 'dedoc') {
            icon = <DEDOC_ICON />;
          }

          return (
            <li key={data.key}>
              <Chip
                icon={icon}
                label={data.label}
                color={data.selected ? 'primary' : 'default'}
                onClick={() => handleTopicSelect(data.key)}
                className={classes.chip}
              />
            </li>
          );
        })}
    </Paper>
  );
};

export default DesignTopics;
