import React, { useEffect } from 'react';
import AudioRecorder from 'audio-recorder-polyfill';

const useAudioRecorder = requestRef => {
  useEffect(() => {
    try {
      if (!window.MediaRecorder) {
        window.MediaRecorder = AudioRecorder;
      }
    } catch (e) {
      alert('Kein AudioRecorder verfügbar');
    }

    return () => {
      cancelAnimationFrame(requestRef.current);
    };
  }, []);
  return <></>;
};

export default useAudioRecorder;

