import React, { useEffect } from 'react';

const useHandleTopicId = (handleTopicId, setTimer, topic) => {
  useEffect(() => {
    switch (topic) {
      case 'wdd':
        return handleTopicId(2);
      case 'xmas':
        return handleTopicId(0);
      case 'bday':
        return handleTopicId(1);
      case 'wedding':
        return handleTopicId(4);
      case 'merrit_amrit':
        return handleTopicId(5);
      case '30':
        handleTopicId(0);
        setTimer('T00:00:30');
        break;
      case 'cXD-45':
        handleTopicId(0);
        setTimer('T00:00:45');
        break;
      case 'cLOF-60':
        handleTopicId(0);
        setTimer('T00:00:60');
        break;
      default:
        return handleTopicId(0);
    }
  }, []);
};

export default useHandleTopicId;
