import React, { useRef, useState } from 'react';
import '../../App.css';
import jingle from '../../assets/backgroundSounds/Christmas_Jingle.mp3';
import birthdaySound from '../../assets/backgroundSounds/HappyBirthday.mp3';
import diabetes from '../../assets/backgroundSounds/world_diabetes.mp3';
import silent from '../../assets/backgroundSounds/silent_60.mp3';
import homecoming_christmas from '../../assets/backgroundSounds/xmas_60.mp3';
import japan from '../../assets/backgroundSounds/bl_japan.mp3';

import LandingPage from './LandingPage/StartRecord';
import LoadingIndicator from './RecordScreen/loadingIndicator';
import RecordAudioVisual from './RecordScreen/RecordAudioVisual';
import { Howl } from 'howler';
import { defaultColor } from '../../utils/color';
import { Button, IconButton } from '@material-ui/core';
import { animationLooper3 } from '../../utils/animationLooper3';
import StyleCardComponent from './Customizer/StyleCardComponent';
import { dbUtils } from '../../utils/dbService';
import {
  birthdayColors,
  christmasColors,
  dedocColors,
  defaultColors,
  diabetesColors,
  weddingColors,
} from '../../utils/paletteColors';
import { useLocation } from 'react-router-dom';
import { PlayArrow } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import MailTo from '../common/MailTo';
import DeleteDialog from '../../utils/Dialog';
import Page from '../common/Page';
import handleUpload from './RecordScreen/uploadAudio';
import useOriginUrl from './useOriginUrl';
import useHandleTopicId from './useHandleTopic';
import useAudioRecorder from './useAudioRecorder';
import { useTranslation } from 'react-i18next';
import SimpleSnackbar from '../../utils/SnackBar';
import { download } from '../../utils/unused/download';

const short = require('short-uuid');

let recorder;
let globalStream;

function AudioRecordPage(props) {
  const { t, i18n } = useTranslation();

  const { auth, step, setStep, topicId, setTopicId } = props;
  const search = useLocation().search;
  const topic = new URLSearchParams(search).get('t');

  const classes = useStyles();
  //const backgroundVolume = 0.7;
  const [backgroundVolume, setBackgroundVolume] = useState(0.7);
  let rotate = 0;
  const [audio, setAudio] = useState(null);
  const [progress, setProgress] = useState(0);
  const [uuid, setUuid] = useState(null);
  const [originUrl, setOriginUrl] = useState('');
  var analyser2 = useRef();
  let shareUrl;
  let canvasRef = useRef();
  let audioContextRecorder = useRef();
  const requestRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  var sound = useRef();
  var backgroundNoise = useRef();
  let frequency_array = useRef();
  const [svgImage, setSvgImage] = useState(1);
  const [text, setText] = useState('');
  const [font, setFont] = useState('25px Caveat');
  const [background, setBackground] = useState(defaultColor);
  const [shared, setShared] = useState(false);

  const [colorPalette, setColorPalette] = useState(defaultColors);
  const [notAllowed, setNotAllowed] = useState(true);
  const [timer, setTimer] = useState('T00:00:15');
  const [proUrl, setProUrl] = useState(null);
  const [proMail, setProMail] = useState(null);
  const handleChangeComplete = color => {
    setBackground(color.hex);
    animationLooper3.draw(frequency_array.current, 0, color.hex, svgImage, text, font);
    cancelAnimationFrame(requestRef.current);
    let data = {
      color: color.hex,
    };
    dbUtils
      .update(uuid, data)
      .then(() => {
        // console.log("Created new item successfully!");
      })
      .catch(e => {
        console.log(e);
      });
  };

  function setDefault(defaultText, defaultColor, defaultImage, defaultFont, colorPalette, topicId, backgroundMusic) {
    setColorPalette(colorPalette);
    setSvgImage(defaultImage);
    setBackground(defaultColor);
    setText(defaultText);
    setTopicId(topicId);
    setFont(defaultFont);

    const data = {
      topic: topicId,
      color: defaultColor,
      svgImage: defaultImage,
      text: defaultText,
      font: defaultFont,
    };

    if (uuid) {
      dbUtils
        .update(uuid, data)
        .then(() => {
          //     console.log("Updated new item successfully!");
        })
        .catch(e => {
          console.log(e);
        });
    }

    if (backgroundNoise.current) {
      backgroundNoise.current.unload();
      backgroundNoise.current._src = backgroundMusic;
      backgroundNoise.current.load();
      animationLooper3.draw(frequency_array.current, 0, defaultColor, defaultImage, defaultText, font);
      cancelAnimationFrame(requestRef.current);
    }
  }

  const handleTopicId = topicId => {
    if (topicId === 0) {
      const defaultText = 'Frohe Weihnachten';
      const defaultColor = '#28413C';
      const defaultImage = 14;
      const defaultFont = '25px Caveat';
      const colorPalette = christmasColors;
      const topicId = 'xmas';
      const backgroundMusic = homecoming_christmas;

      setDefault(defaultText, defaultColor, defaultImage, defaultFont, colorPalette, topicId, backgroundMusic);
    } else if (topicId === 1) {
      const defaultText = 'Alles Gute zum 30.';
      const defaultColor = '#FF8E00';
      const defaultImage = 16;
      const defaultFont = '25px Caveat';
      const colorPalette = birthdayColors;
      const topicId = 'bday';
      const backgroundMusic = birthdaySound;
      setDefault(defaultText, defaultColor, defaultImage, defaultFont, colorPalette, topicId, backgroundMusic);
    } else if (topicId === 2) {
      const defaultText = 'Welt-Diabetes-Tag';
      const defaultColor = '#578AD6';
      const defaultImage = 4;
      const defaultFont = '25px Caveat';
      const colorPalette = diabetesColors;
      const topicId = 'wdd';
      const backgroundMusic = diabetes;
      setDefault(defaultText, defaultColor, defaultImage, defaultFont, colorPalette, topicId, backgroundMusic);
    } else if (topicId === 3) {
      const defaultText = 'MERRY CHRISTMAS';
      const defaultColor = '#0d4a8d';
      const defaultImage = 7;
      const defaultFont = '25px Caveat';
      const colorPalette = dedocColors;
      const topicId = 'dedoc';
      const backgroundMusic = diabetes;
      setDefault(defaultText, defaultColor, defaultImage, defaultFont, colorPalette, topicId, backgroundMusic);
    } else if (topicId === 4) {
      const defaultText = 'Just married';
      const defaultColor = '#edb800';
      const defaultImage = 11;
      const defaultFont = '25px Caveat';
      const colorPalette = weddingColors;
      const topicId = 'wedding';
      const backgroundMusic = diabetes;
      setDefault(defaultText, defaultColor, defaultImage, defaultFont, colorPalette, topicId, backgroundMusic);
    } else if (topicId === 5) {
      // MERRIT / AMRIT
      const defaultText = 'Just married';
      const defaultColor = '#edb800';
      const defaultImage = 11;
      const defaultFont = '25px Caveat';
      const colorPalette = weddingColors;
      const topicId = 'wedding';
      const backgroundMusic = diabetes;
      setDefault(defaultText, defaultColor, defaultImage, defaultFont, colorPalette, topicId, backgroundMusic);
    } else if (topicId === 6) {
      // DCB
      const defaultText = 'MERRY CHRISTMAS';
      const defaultImage = 12;
      const defaultColor = '#254c3a';
      const defaultFont = '25px Calibri Light';
      const colorPalette = christmasColors;
      const topicId = 'dcb';
      const backgroundMusic = silent;
      setDefault(defaultText, defaultColor, defaultImage, defaultFont, colorPalette, topicId, backgroundMusic);
    } else if (topicId === 7) {
      const defaultText = 'MERRY CHRISTMAS';
      const defaultImage = 13;
      const defaultColor = '#7d050c';
      const defaultFont = '25px Caveat';
      const colorPalette = christmasColors;
      const topicId = 'bsl';
      const backgroundMusic = jingle;
      setDefault(defaultText, defaultColor, defaultImage, defaultFont, colorPalette, topicId, backgroundMusic);
    } else if (topicId === 8) {
      const defaultText = 'Vielen Dank';
      const defaultImage = 21;
      const defaultColor = '#d4d0c8';
      const defaultFont = '25px Caveat';
      const colorPalette = christmasColors;
      const topicId = 'go_sake';
      const backgroundMusic = japan;
      setDefault(defaultText, defaultColor, defaultImage, defaultFont, colorPalette, topicId, backgroundMusic);
    } else if (topicId === 9) {
      const defaultText = 'MERRY CHRISTMAS';
      const defaultImage = 22;
      const defaultColor = '#0e2184';
      const defaultFont = '25px Caveat';
      const colorPalette = christmasColors;
      const topicId = 'mdi';
      const backgroundMusic = homecoming_christmas;
      setDefault(defaultText, defaultColor, defaultImage, defaultFont, colorPalette, topicId, backgroundMusic);
    } else {
      const defaultText = 'Frohe Weihnachten';
      const defaultImage = 1;
      const defaultColor = background;
      const defaultFont = '25px Caveat';
      const colorPalette = christmasColors;
      const topicId = 'xmas';
      const backgroundMusic = homecoming_christmas;
      setDefault(defaultText, defaultColor, defaultImage, defaultFont, colorPalette, topicId, backgroundMusic);
    }
  };

  useHandleTopicId(handleTopicId, setTimer, topic);

  const handleSvgImageChange = svgImageNumer => {
    setSvgImage(svgImageNumer);
    animationLooper3.draw(frequency_array.current, 0, background, svgImageNumer, text);
    cancelAnimationFrame(requestRef.current);

    let data = {
      svgImage: svgImageNumer,
    };
    dbUtils
      .update(uuid, data)
      .then(() => {
        //  console.log("Created new item successfully!");
      })
      .catch(e => {
        console.log(e);
      });
  };

  useAudioRecorder(requestRef);

  function getProgress(p) {
    setProgress(p);
  }

  useOriginUrl(setOriginUrl);

  const replay = () => {
    globalStream
      .getTracks() // get all tracks from the MediaStream
      .forEach(track => track.stop());
  };

  function howler(url) {
    sound.current = new Howl({
      src: url,
      onend: function () {
        cancelAnimationFrame(requestRef.current);
        setIsPlaying(false);
        rotate = 0;
        //  download('downloadCanvas', uuid, auth.user.uid)
      },
    });

    let bgSound;
    if (topic === 'wdd') {
      bgSound = diabetes;
    } else if (topic === 'xmas') {
      bgSound = homecoming_christmas;
    } else if (topic === 'dcb' || topic === 'cXD-45' || topic === 'bsl') {
      bgSound = silent;
    } else if (topic === 'bday') {
      bgSound = birthdaySound;
    } else if (topic === 'cLOF-60') {
      bgSound = homecoming_christmas;
    } else if (topic === 'go_sake') {
      bgSound = japan;
    } else {
      bgSound = homecoming_christmas;
    }

    backgroundNoise.current = new Howl({
      src: bgSound,
      html5: true,
      volume: backgroundVolume,
      onend: function () {},
    });

    sound.current.on('play', function () {
      var fadeouttime = 1000;
      setTimeout(function () {
        backgroundNoise.current.fade(backgroundVolume, 0, fadeouttime);
      }, (sound.current.duration() - sound.current.seek()) * 1000 - fadeouttime);
    });

    backgroundNoise.current.on('fade', function () {
      backgroundNoise.current.stop();
    });

    // Create an analyser node in the Howler WebAudio context
    const hwl = window.hwl;
    analyser2.current = hwl.ctx.createAnalyser();
    hwl.masterGain.connect(analyser2.current);
    frequency_array.current = new Uint8Array(analyser2.current.frequencyBinCount);
    animationLooper3.createCanvas(canvasRef.current, true, 400, 350);
    animationLooper3.draw(frequency_array.current, 0, background, svgImage, text, font);
    cancelAnimationFrame(requestRef.current);
  }

  function isShared() {
    setShared(true);
  }

  const onStart = () => {
    setShared(false);
    setIsPlaying(false);
    const id = short.generate();
    setUuid(id);

    try {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then(stream => {
          const options = {
            mimeType: 'video/wav;',
          };
          setNotAllowed(false);
          globalStream = stream;
          const audioStream = stream.clone();
          setAudio(audioStream);
          recorder = new MediaRecorder(audioStream);
          recorder.start();
          const chunks = [];
          recorder.addEventListener('dataavailable', e => {
            chunks.push(e.data);
            console.log(e.data.type);
            setStep(3);
            const fileReader = new FileReader();
            fileReader.addEventListener('load', e => {
              if (e && e.target && e.target.result && chunks !== null) {
                const arrayBuffer = e.target.result;
                const base64Str = Buffer.from(arrayBuffer).toString('base64');
                const contentType = 'audio/mp3';
                const srcUrl = [`data:${contentType};base64,${base64Str}`];
                howler(srcUrl);
              }
            });
            fileReader.readAsArrayBuffer(new Blob(chunks));
            handleUpload(
              e.data,
              id,
              originUrl,
              getProgress,
              auth.user.uid,
              background,
              text,
              topic,
              svgImage,
              font,
              proUrl,
              proMail
            );
            replay();

            return () => {
              console.log('close');
            };
          });
        })
        .catch(err => {
          console.log(err);
          if (err.name === 'NotFoundError' || err.name === 'DevicesNotFoundError') {
            //required track is missing
            // eslint-disable-next-line eqeqeq
          } else if (err.name === 'NotReadableError' || err.name === 'TrackStartError') {
            //webcam or mic are already in use
          } else if (err.name === 'OverconstrainedError' || err.name === 'ConstraintNotSatisfiedError') {
            //constraints can not be satisfied by avb. devices
          } else if (err.name === 'NotAllowedError' || err.name === 'PermissionDeniedError') {
            //permission denied in browser
            setNotAllowed(true);
          } else if (err.name === 'TypeError' || err.name === 'TypeError') {
            //empty constraints object
          } else {
            //other errors
          }
        });
    } catch (e) {
      alert(
        'Dein Browser unterstützt keine Audioaufnahme. Wenn du ein Iphone hast, kannst du Safari zur Audioaufnahme verwenden. (ab iPhone 5S'
      );
    }

    setStep(2);
  };

  function playAudio() {
    rotate = 0;
    setIsPlaying(true);
    sound.current.play();
    backgroundNoise.current.volume(backgroundVolume);
    backgroundNoise.current.play();
    tick();
  }

  const tick = () => {
    analyser2.current.getByteTimeDomainData(frequency_array.current);
    animationLooper3.draw(frequency_array.current, rotate, background, svgImage, text, font);
    requestRef.current = requestAnimationFrame(tick);
    rotate += (2 * Math.PI) / 180;
  };

  const checkTime = time => {
    console.log(time);
    if (time.toString() === 'T0:0:0') {
      recorder.stop();
      recorder.stream.getTracks().forEach(i => i.stop());
      //setIsRecording(false);
      setAudio(null);
      rotate = 0;
    }
  };

  const onStop = () => {
    if (recorder) {
      recorder.stop();
      recorder.stream.getTracks().forEach(i => i.stop());
      rotate = 0;
      // Update line buffer based on frequency data
    }
    setStep(3);
    setAudio(null);
  };
  shareUrl = `${originUrl}/p/${uuid}?sh=w`;

  function handleChangeText(event) {
    setText(event);
    animationLooper3.draw(frequency_array.current, 0, background, svgImage, event, font);
    cancelAnimationFrame(requestRef.current);
    let data = {
      text: event,
    };
    dbUtils
      .update(uuid, data)
      .then(() => {
        console.log('Created new item successfully!');
      })
      .catch(e => {
        console.log(e);
      });
  }

  function handleChangeUrl(event) {
    setProUrl(event);
    let data = {
      proUrl: event,
    };
    dbUtils
      .update(uuid, data)
      .then(() => {
        console.log('Created new item successfully!');
      })
      .catch(e => {
        console.log(e);
      });
  }

  function handleChangeMail(event) {
    setProMail(event);
    let data = {
      proMail: event,
    };
    dbUtils
      .update(uuid, data)
      .then(() => {
        console.log('Created new item successfully!');
      })
      .catch(e => {
        console.log(e);
      });
  }

  // STARTSEITE
  if (step === 0) {
    return <LandingPage setStep={setStep} onStart={onStart} />;
  }
  // RECORD SCREEN
  if (step === 1 || step === 2) {
    return (
      <Page>
        <RecordAudioVisual
          onStart={onStart}
          setStep={setStep}
          notAllowed={notAllowed}
          step={step}
          timer={timer}
          onStop={onStop}
          audio={audio}
          checkTime={checkTime}
          audioContextRecorder={audioContextRecorder}
        />
      </Page>
    );
  } else if (step === 3) {
    // EDIT CANVAS
    return (
      <Page style={{ minHeight: '100%' }} color={'#f2f0ed'}>
        <>
          <div style={{ marginTop: 10 }} />
          <canvas style={{ height: 400 }} className={classes.canvas} id="downloadCanvas" ref={canvasRef} />
          {progress >= 0 ? (
            <>
              {!isPlaying ? (
                <>
                  <IconButton className={classes.play} onClick={() => playAudio()}>
                    <PlayArrow style={{ color: '#fff' }} fontSize={'large'} />
                  </IconButton>
                  <StyleCardComponent
                    topicId={topicId}
                    background={background}
                    colorPalette={colorPalette}
                    handleChangeComplete={handleChangeComplete}
                    handleChangeText={handleChangeText}
                    handleChangeMail={handleChangeMail}
                    handleChangeUrl={handleChangeUrl}
                    proMail={proMail}
                    proUrl={proUrl}
                    handleSvgImageChange={handleSvgImageChange}
                    handleTopicId={handleTopicId}
                    text={text}
                    id={uuid}
                    userId={auth.user.uid}
                  />
                </>
              ) : null}
              <div className={classes.buttonGroup}>
                <DeleteDialog isPlaying={isPlaying} shared={shared} onStart={onStart} uuid={uuid} setStep={setStep} />
                <SimpleSnackbar isShared={isShared} url={shareUrl} />
                <canvas id="qr" className={classes.qrcode} />
                <div>
                  <Button onClick={() => download('qr')}> {t('style_page.download_qr_code')}</Button>
                </div>
              </div>

              <div className={classes.footer}>
                <MailTo variant={'h5'} text={t('style_page.custom_individual')} />
              </div>

            </>
          ) : (
            <LoadingIndicator progress={progress} />
          )}
        </>
      </Page>
    );
  }
}

export default AudioRecordPage;

const useStyles = makeStyles(theme => {
  const buttonSize = 65;
  return {
    buttonGroup: {
      display: 'flex',
      marginBottom: 12,
      marginTop: 12,
      justifyContent: 'space-evenly',

      flexDirection: 'column',
      height: 190,
      [theme.breakpoints.up('md')]: {
        width: '70%',
        flexDirection: 'row',
        height: 90,
      },
    },
    qrcode: {
      display: 'none',
    },

    footer: {
      width: '100%',
      height: 440,
      backgroundColor: '#ffffff',
      marginTop: 0,
      display: 'flex',
      alignItems: 'center',
    },
    spacer: { height: 100 },
    canvas: {
      opacity: 1,
      animationName: '$fadeInOpacity',
      animationIterationCount: 1,
      animationTimingFunction: 'ease-in',
      animationDuration: '.8s',
    },
    '@keyframes fadeInOpacity': {
      '0%': {
        opacity: 0,
      },
      '100%': {
        opacity: 1,
      },
    },
    canvasContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignContent: 'center',
      width: '100%',
    },
    play: {
      //  margin: 'auto',
      top: -20,
      //width: buttonSize,
      //height: buttonSize,
      // alignSelf: 'center',
      position: 'relative',
      // left: buttonSize / 2,
      // transform: `translate(-50%, -50%)`,
      boxShadow: '4px 4px 6px 0px #0003',
      backgroundColor: '#d8b68f',
      '&:hover': { backgroundColor: '#AE7639' },
    },
    stop: {
      width: buttonSize,
      height: buttonSize,
      alignSelf: 'center',
      position: 'relative',
      left: buttonSize / 2,
      transform: `translate(-50%, -50%)`,
      backgroundColor: '#d8b68f',
      '&:hover': { backgroundColor: '#AE7639' },
      boxShadow: '4px 4px 6px 0px #0003',
    },
  };
});
