import React from 'react';
import { createStyles, makeStyles, Typography } from '@material-ui/core';

const ListItem = props => {
  const classes = useStyles();

  return (
    <li className={classes.listItem} style={props.center ? { alignItems: 'center' } : null}>
      {props.icon && <div className={classes.round}> {props.icon}</div>}
      <Typography
        className={classes.subtitle2}
        style={props.center ? { textAlign: 'center' } : null}
        variant={'subtitle2'}
      >
        {props.title}
      </Typography>
      <Typography className={classes.body} style={props.center ? { textAlign: 'center' } : null} variant={'body1'}>
        {props.description}
      </Typography>
    </li>
  );
};

export default ListItem;

const useStyles = makeStyles(theme =>
  createStyles({
    subtitle2: {
      paddingTop: 10,
      color: '#35414B',
      fontFamily: 'Inter',
      fontWeight: '500',
      fontSize: '1.2em',
      textAlign:'left',
      [theme.breakpoints.down('sm')]: {
        textAlign:'center',
      },
    },
    body: {
      paddingTop: 10,
      color: '#35414B',
      fontFamily: 'Inter',
      fontWeight: 'normal',
      fontSize: '1em',
      textAlign:'left',
      [theme.breakpoints.down('sm')]: {
        textAlign:'center',
      },

    },
    listItem: {
      width: 350,
      padding: theme.spacing(3,2),
      margin: theme.spacing (0,3),
      display: 'flex',
      justifyContent: 'flex-start',
      flexDirection: 'column',
      alignItems: 'flex-start',
      [theme.breakpoints.up('lg')]: {
        margin: theme.spacing (0,6),

      },
      [theme.breakpoints.down('sm')]: {
        alignItems: 'center',
        justifyContent: 'center',
        width: 350,
        margin: theme.spacing (0,1),

      },
    },
    round: {
     // border: '2px solid #933D38',
      backgroundColor: '#164227',
      borderRadius: '50%',
      width: 50,
      height: 50,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  })
);
