import React, { useEffect } from 'react';

const useOriginUrl = setOriginUrl => {
  useEffect(() => {
    const hostname = window && window.location && window.location.hostname;

    if (hostname === 'https://voicecards-691cb.web.app/') {
      setOriginUrl('https://voicecards-691cb.web.app/');
    } else {
      setOriginUrl(window.location.origin);
      console.log('jo');
    }
  }, []);
};

export default useOriginUrl;


