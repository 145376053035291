import QRious from 'qrious';

const generateQR = (id, originUrl) => {
  new QRious({
    element: document.getElementById('qr'),
    value: `${originUrl}/p/${id}`,
    // padding: 25,
    size: 200,
  });
};

export default generateQR;
