import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid } from '@material-ui/core';
import { Link, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ExampleKlangKarte = props => {
  const { t, i18n } = useTranslation();
  let match = useRouteMatch();

  const classes = useStyles();
  return (
    <Grid item>
      <div className={classes.list}>
        <img src={props.image} className={classes.image} />
        {props.id ? (
          <Button component={Link} to={`${match.url}/${props.id}`}>
            {t('examples.show')}
          </Button>
        ) : null}
      </div>
    </Grid>
  );
};

export default ExampleKlangKarte;

const useStyles = makeStyles(() => {
  return {
    list: {
      display: 'flex',
      flexDirection: 'column',
    },
    image: {
      width: 200,
    },
  };
});
