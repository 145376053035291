import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { TwitterPicker } from 'react-color';
import { IconButton, TextField } from '@material-ui/core';
import {
  BLUE_CIRCLE_ICON,
  DEDOC_ICON,
  DEER_ICON,
  GINGERBREAD_ICON,
  HAPPY_BIRTHDAY_PATH_01,
  INSULIN_PUMP_ICON,
  LETS_PARTY_ICON,
  LOVE_CHAT_ICON,
  MONSTER_BLUE_CIRCLE_ICON,
  PARTY_ICON,
  PENGUIN_ICON,
  RING_LOVE_ICON,
  SANTA_ICON,
  SLIM_RING_ICON,
  SNOW_FLAKE_1_ICON,
  SNOW_FLAKE_2_ICON,
  SNOW_FLAKE_3_ICON,
  THANK_YOU_ICON,
  TREE_ICON,
  TREE_PRESENT_ICON,
  WEDDING_ICON,
} from '../../../visuals/icons/icons';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { Box, TextArea } from 'grommet';
import DesignTopics from './DesignTopics';
import { useLocation } from 'react-router-dom';
import { PictureUploadCrop } from './cropImage/crop';
import MailTo from '../../common/MailTo';
import { useTranslation } from 'react-i18next';

function StyleCardComponent(props) {
  const { t, i18n } = useTranslation();

  const {
    background,
    topicId,
    handleTopicId,
    colorPalette,
    handleChangeComplete,
    handleChangeText,
    handleChangeUrl,
    handleChangeMail,
    handleSvgImageChange,
    proUrl,
    proMail,
    text,
    id,
    userId,
  } = props;
  const classes = useStyles();
  const search = useLocation().search;
  const topic = new URLSearchParams(search).get('t');

  console.log(topic);
  const [expanded, setExpanded] = React.useState(null);
  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className={classes.root}>
      {topic !== 'bsl' ? (
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2bh-content" id="panel2bh-header">
            <div className={classes.column}>
              <Typography className={classes.heading}>{t('style_page.topic')}</Typography>
            </div>
            <div className={classes.column}>
              <Typography className={classes.secondaryHeading}>{t('style_page.topic_description')}</Typography>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            {topic !== 'dcb' || topic !== 'go_sake' ? (
              <DesignTopics handleTopicId={handleTopicId} />
            ) : (
              <Typography>Premium Thema aktiv</Typography>
            )}
          </AccordionDetails>
        </Accordion>
      ) : null}
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2bh-content" id="panel2bh-header">
          <div className={classes.column}>
            <Typography className={classes.heading}>{t('style_page.illustration')}</Typography>
          </div>
          <div className={classes.column}>
            <Typography className={classes.secondaryHeading}>{t('style_page.illustration_description')}</Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            <div style={{ paddingBottom: 15, paddingTop: 10 }}>
              {topicId === 'go_sake' && <Typography>Premium Design GO SAKE</Typography>}

              {topicId === 'bsl' && <Typography>Blood Sugar Lounge</Typography>}
              {topicId === 'dcb' && <Typography>Logo – Diabetes Center Bern</Typography>}
              {topicId === 'xmas' && (
                <>
                  <IconButton style={{ fontSize: 60 }} onClick={() => handleSvgImageChange(14)}>
                    <TREE_PRESENT_ICON fontSize={'inherit'} />
                  </IconButton>
                  <IconButton style={{ fontSize: 60 }} onClick={() => handleSvgImageChange(15)}>
                    <PENGUIN_ICON fontSize={'inherit'} />
                  </IconButton>
                  <IconButton style={{ fontSize: 60 }} onClick={() => handleSvgImageChange(2)}>
                    <SANTA_ICON fontSize={'inherit'} />
                  </IconButton>
                  <IconButton style={{ fontSize: 60 }} onClick={() => handleSvgImageChange(1)}>
                    <TREE_ICON fontSize={'inherit'} />
                  </IconButton>
                  <IconButton style={{ fontSize: 60 }} onClick={() => handleSvgImageChange(17)}>
                    <DEER_ICON fontSize={'inherit'} />
                  </IconButton>
                  <IconButton style={{ fontSize: 60 }} onClick={() => handleSvgImageChange(18)}>
                    <GINGERBREAD_ICON fontSize={'inherit'} />
                  </IconButton>
                  <IconButton style={{ fontSize: 60 }} onClick={() => handleSvgImageChange(23)}>
                    <SNOW_FLAKE_1_ICON fontSize={'inherit'} />
                  </IconButton>
                  <IconButton style={{ fontSize: 60 }} onClick={() => handleSvgImageChange(24)}>
                    <SNOW_FLAKE_2_ICON fontSize={'inherit'} />
                  </IconButton>
                  <IconButton style={{ fontSize: 60 }} onClick={() => handleSvgImageChange(25)}>
                    <SNOW_FLAKE_3_ICON fontSize={'inherit'} />
                  </IconButton>
                </>
              )}
              {topicId === 'bday' && (
                <>
                  <IconButton pad={'medium'} onClick={() => handleSvgImageChange(3)}>
                    <PARTY_ICON fontSize={'large'} />
                  </IconButton>
                  <IconButton pad={'medium'} onClick={() => handleSvgImageChange(16)}>
                    <HAPPY_BIRTHDAY_PATH_01 fontSize={'large'} />
                  </IconButton>
                  <IconButton pad={'medium'} onClick={() => handleSvgImageChange(19)}>
                    <LETS_PARTY_ICON fontSize={'large'} />
                  </IconButton>
                  <IconButton pad={'medium'} onClick={() => handleSvgImageChange(20)}>
                    <THANK_YOU_ICON fontSize={'large'} />
                  </IconButton>
                </>
              )}
              {topicId === 'wedding' && (
                <>
                  <IconButton pad={'medium'} onClick={() => handleSvgImageChange(8)}>
                    <WEDDING_ICON fontSize={'large'} />
                  </IconButton>
                  <IconButton pad={'medium'} onClick={() => handleSvgImageChange(9)}>
                    <LOVE_CHAT_ICON fontSize={'large'} />
                  </IconButton>
                  <IconButton pad={'medium'} onClick={() => handleSvgImageChange(10)}>
                    <SLIM_RING_ICON fontSize={'large'} />
                  </IconButton>{' '}
                  <IconButton pad={'medium'} onClick={() => handleSvgImageChange(11)}>
                    <RING_LOVE_ICON fontSize={'large'} />
                  </IconButton>
                </>
              )}
              {topicId === 'wdd' && (
                <>
                  <IconButton pad={'medium'} onClick={() => handleSvgImageChange(4)}>
                    <BLUE_CIRCLE_ICON fontSize={'large'} />
                  </IconButton>

                  <IconButton pad={'medium'} onClick={() => handleSvgImageChange(5)}>
                    <MONSTER_BLUE_CIRCLE_ICON fontSize={'large'} />
                  </IconButton>
                  <IconButton pad={'medium'} onClick={() => handleSvgImageChange(6)}>
                    <INSULIN_PUMP_ICON fontSize={'large'} />
                  </IconButton>
                  <IconButton pad={'medium'} onClick={() => handleSvgImageChange(20)}>
                    <THANK_YOU_ICON fontSize={'large'} />
                  </IconButton>
                </>
              )}
              {topic === 'dedoc' && (
                <>
                  <IconButton pad={'medium'} onClick={() => handleSvgImageChange(7)}>
                    <DEDOC_ICON fontSize={'large'} />
                  </IconButton>

                  <IconButton onClick={() => handleSvgImageChange(2)}>
                    <SANTA_ICON fontSize={'large'} />
                  </IconButton>
                  <IconButton pad={'medium'} onClick={() => handleSvgImageChange(1)}>
                    <TREE_ICON fontSize={'large'} />
                  </IconButton>
                  <IconButton pad={'medium'} onClick={() => handleSvgImageChange(4)}>
                    <BLUE_CIRCLE_ICON fontSize={'large'} />
                  </IconButton>
                  <IconButton style={{ fontSize: 60 }} onClick={() => handleSvgImageChange(14)}>
                    <TREE_PRESENT_ICON fontSize={'inherit'} />
                  </IconButton>
                  <IconButton pad={'medium'} onClick={() => handleSvgImageChange(5)}>
                    <MONSTER_BLUE_CIRCLE_ICON fontSize={'large'} />
                  </IconButton>
                  <IconButton pad={'medium'} onClick={() => handleSvgImageChange(6)}>
                    <INSULIN_PUMP_ICON fontSize={'large'} />
                  </IconButton>
                  <IconButton pad={'medium'} onClick={() => handleSvgImageChange(20)}>
                    <THANK_YOU_ICON fontSize={'large'} />
                  </IconButton>
                </>
              )}
            </div>
          </Box>
        </AccordionDetails>
      </Accordion>
      {/*///COLOR*/}
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1c-content" id="panel1c-header">
          <div className={classes.column}>
            <Typography className={classes.heading}>{t('style_page.color')}</Typography>
          </div>
          <div className={classes.column}>
            <Typography className={classes.secondaryHeading}>{t('style_page.color_description')}</Typography>
          </div>
        </AccordionSummary>

        <AccordionDetails style={{ flexDirection: 'column', alignItems: 'center' }}>
          <Box>
            <TwitterPicker color={background} colors={colorPalette} onChangeComplete={handleChangeComplete} />
          </Box>
        </AccordionDetails>
      </Accordion>

      {/*///Text*/}

      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3bh-content" id="panel3bh-header">
          <div className={classes.column}>
            <Typography className={classes.heading}>{t('style_page.text')}</Typography>
          </div>
          <div className={classes.column}>
            <Typography className={classes.secondaryHeading}>{t('style_page.text_description')} </Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <TextArea style={{ paddingTop: 15 }} value={text} onChange={event => handleChangeText(event.target.value)} />
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel5bh-content" id="panel5bh-header">
          <div className={classes.column}>
            <Typography className={classes.heading}>{t('style_page.image')}</Typography>
          </div>
          <div className={classes.column}>
            <Typography className={classes.secondaryHeading}>{t('style_page.image_description')}</Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails style={{ display: 'inline' }}>
          {topic === 'dcb' ||
          topic === '30' ||
          topic === 'bsl' ||
          topic === 'cXD-45' ||
          topic === 'cLOF-60' ||
          topic === 'mdi' ||
          topic === 'go_sake' ? (
            <PictureUploadCrop id={id} userId={userId} />
          ) : (
            <MailTo text={t('style_page.custom_logo')} />
          )}
        </AccordionDetails>
      </Accordion>
      {topic !== 'bsl' && (
        <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel7bh-content" id="panel7bh-header">
            <div className={classes.column}>
              <Typography className={classes.heading}>{t('style_page.pro_url')}</Typography>
            </div>
            <div className={classes.column}>
              <Typography className={classes.secondaryHeading}>{t('style_page.pro_url_description')}</Typography>
            </div>
          </AccordionSummary>
          {topic === '30' || topic === 'cXD-45' || topic === 'cLOF-60' || topic === 'go_sake' ? (
            <AccordionDetails style={{ flexDirection: 'column' }}>
              <Typography variant={'body2'} className={classes.textField}>
                {t('style_page.pro_url_description')}
              </Typography>
              <TextField
                label="Website"
                variant="outlined"
                value={proUrl}
                onChange={event => handleChangeUrl(event.target.value)}
                helperText={'https://yourwebpage.com'}
                size={'small'}
                type={'url'}
              />
              <Typography variant={'body2'} className={classes.textField}>
                {t('style_page.pro_email_description')}
              </Typography>
              <TextField
                variant="outlined"
                label="E-Mail"
                value={proMail}
                onChange={event => handleChangeMail(event.target.value)}
                helperText={'your@email.com'}
                size={'small'}
                type={'email'}
              />
            </AccordionDetails>
          ) : (
            <MailTo text={t('style_page.custom_link')} />
          )}
        </Accordion>
      )}
    </div>
  );
}

export default StyleCardComponent;
const useStyles = makeStyles(theme => ({
  root: {
    width: '70%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  heading: {
    minWidth: 55,
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  icon: {
    verticalAlign: 'bottom',
    height: 20,
    width: 20,
  },
  details: {
    alignItems: 'center',
  },
  column: {
    paddingLeft: theme.spacing(2),
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  textField: {
    marginBottom: 12,
    marginTop: 12,
  },
}));
