import React, { useCallback, useState } from 'react';
import Cropper from 'react-easy-crop';
import Slider from '@material-ui/core/Slider';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { getCroppedImg } from './canvasUtils';
import { storage } from '../../../../firebase';
import { dbUtils } from '../../../../utils/dbService';
import { blobFromURL } from '../../../../utils/blobFromUrl';
import { Spinner } from 'grommet';
import Compressor from 'compressorjs';

const ORIENTATION_TO_ANGLE = {
  3: 180,
  6: 90,
  8: -90,
};

export const PictureUploadCrop = ({ userId, id }) => {
  const classes = useStyles();

  const [imageSrc, setImageSrc] = React.useState(null);
  const [croppedImage, setCroppedImage] = useState(null);

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [loading, setLoading] = useState(false);
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  function removeImage() {
    setCroppedImage(null);
    let data = {
      picturePath: null,
    };
    dbUtils
      .update(id, data)
      .then(() => {
        console.log('update picture path - successfully!');
      })
      .catch(e => {
        console.log(e);
      });
  }

  const showCroppedImage = useCallback(async () => {
    setLoading(true);



    try {
      const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels, rotation);
      console.log('donee', { croppedImage });
      setCroppedImage(croppedImage);
      setImageSrc(null);
      const blob = await blobFromURL(croppedImage);

      const path = `images/${userId}/picture_${id}.png`;
      storage
        .ref(path)
        .put(blob)
        .then(() => {
          console.log('upload successful');
          let data = {
            picturePath: path,
          };
          dbUtils
            .update(id, data)
            .then(() => {
              console.log('update picture path - successfully!');
              setLoading(false);
            })
            .catch(e => {
              console.log(e);
            });
        });


    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  }, [imageSrc, croppedAreaPixels, rotation]);


  const onFileChange = async e => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      let imageDataUrl = await readFile(file);

      // apply rotation if needed
     /* const orientation = await getOrientation(file);
      const rotation = ORIENTATION_TO_ANGLE[orientation];
      console.log(rotation)
      if (rotation) {
        imageDataUrl = await getRotatedImage(imageDataUrl, rotation);
      }
*/

      new Compressor(file, {
        quality: 0.92,
        maxWidth: 1050,
        // The compression process is asynchronous,
        // which means you have to access the `result` in the `success` hook function.
        success(result) {
          // Send the compressed image file to server with XMLHttpRequest.
          console.log(result)
          const objectURL = URL.createObjectURL(result);

          setImageSrc(objectURL);

        },
        error(err) {
          console.log(err.message);
        },
      });


    }

  };

  return (
    <div>
      {imageSrc ? (
        <React.Fragment>
          <div className={classes.cropContainer}>
            <Cropper
              image={imageSrc}
              crop={crop}
              rotation={rotation}
              zoom={zoom}
              aspect={4 / 3}
              onCropChange={setCrop}
              onRotationChange={setRotation}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </div>
          <div className={classes.controls}>
            <div className={classes.sliderContainer}>
              <Typography variant="overline" classes={{ root: classes.sliderLabel }}>
                Zoom
              </Typography>
              <Slider
                value={zoom}
                min={1}
                max={3}
                step={0.1}
                aria-labelledby="Zoom"
                classes={{ root: classes.slider }}
                onChange={(e, zoom) => setZoom(zoom)}
              />
            </div>
            <div className={classes.sliderContainer}>
              <Typography variant="overline" classes={{ root: classes.sliderLabel }}>
                Rotation
              </Typography>
              <Slider
                value={rotation}
                min={0}
                max={360}
                step={1}
                aria-labelledby="Rotation"
                classes={{ root: classes.slider }}
                onChange={(e, rotation) => setRotation(rotation)}
              />
            </div>
            <Button
              onClick={showCroppedImage}
              variant="contained"
              color="primary"
              classes={{ root: classes.cropButton }}
            >
              Okay
            </Button>
          </div>
          {/*<ImgDialog img={croppedImage} onClose={onClose} />*/}
        </React.Fragment>
      ) : loading ? (
        <div className={classes.imgContainer}>
          <Spinner />
        </div>
      ) : (
        <div className={classes.imgContainer}>
            <Button variant="contained" component="label">
              Datei auswählen
              <input type="file" id={'file-upload'} onChange={onFileChange} accept="image/*" hidden />
            </Button>
            <br />
            {croppedImage && (
              <>
                <img src={croppedImage} alt="Cropped" className={classes.img} />
                <Button onClick={() => removeImage()}>Bild löschen</Button>
              </>
            )}
        </div>
      )}
    </div>
  );
};

function readFile(file) {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}

const useStyles = makeStyles(theme => ({
  imgContainer: {
    position: 'relative',
    flex: 1,
    padding: 16,
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  img: {
    maxWidth: '80%',
  },

  cropContainer: {
    position: 'relative',
    width: '100%',
    height: 200,
    background: '#333',
    [theme.breakpoints.up('sm')]: {
      height: 400,
    },
  },
  cropButton: {
    flexShrink: 0,
    marginLeft: 16,
  },
  controls: {
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  sliderContainer: {
    display: 'flex',
    flex: '1',
    alignItems: 'center',
  },
  sliderLabel: {
    [theme.breakpoints.down('xs')]: {
      minWidth: 65,
    },
  },
  slider: {
    padding: '22px 0px',
    marginLeft: 16,
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
      margin: '0 16px',
    },
  },
}));
