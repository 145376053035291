import React from 'react';
import Box from '@material-ui/core/Box';
import { Main } from 'grommet';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const LegalNote = () => {
  const classes = useStyles();
  return (
    <Main pad={'medium'}>
      <Box>
        <Typography variant={'subtitle1'}>Impressum</Typography>
        <ul className={classes.list}>
          <Typography variant={'subtitle2'}> Angaben gemäß § 5 TMG</Typography>
          <Typography variant={'body2'}>
            <ul className={classes.list}>
              <li> lumind solutions GmbH</li>
              <li> Dolziger Str. 7</li>
              <li> 10247 Berlin</li>
              <li> Kontakt:</li>
              <li> Telefon: +49 176 913 434 98</li>
              <li> E-Mail: kr@lumind-solutions.com</li>
            </ul>
          </Typography>
          <Typography variant={'subtitle2'}>
            <li>
              Verantwortlich für den Inhalt <br /> nach § 55 Abs. 2 RStV:
            </li>
          </Typography>
          <Typography variant={'body2'}>
            <ul className={classes.list}>
              <li> Kevin Röhl</li>
              <li> Dolziger Str. 7</li>
              <li> 10247 Berlin</li>
            </ul>
          </Typography>
          <Typography>v.1.3</Typography>
        </ul>
      </Box>
    </Main>
  );
};

const useStyles = makeStyles(theme => {
  return {
    list: {
      listStyleType: 'none',
      padding: theme.spacing(2),
    },
  };
});

export default LegalNote;
