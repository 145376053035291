import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, NavLink, Route, Switch } from 'react-router-dom';
import AudioRecordPage from './pages/AudioRecordPage/AudioRecordPage';
import UrlAudioPlayer from './pages/UrlAudioPlayer/UrlAudioPlayerPage';
import { Typography } from '@material-ui/core';
import AppBar from './pages/AppBar';
import firebase from 'firebase';
import 'firebase/auth';
import { FirebaseAuthConsumer, IfFirebaseAuthed } from '@react-firebase/auth';
import LegalNote from './pages/privacy/LegalNote';
import QRScan from './pages/scan/Scan';
import Gaestebuch from './pages/merrit_amrit/Gaestebuch';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

function App() {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const [step, setStep] = useState(0);
  const [topicId, setTopicId] = useState();

  useEffect(() => {
    firebase.auth().signInAnonymously();
  }, []);

  function resetTopicAndStep() {
    if (step !== 2) {
      setStep(0);
    }
  }

  return (
    <>
      <Router>
        <AppBar>
          <Typography variant="h6" color="inherit" noWrap>
            <NavLink to={'/'} onClick={() => resetTopicAndStep()}>
              <span className="withScaling">{t('start.title')}</span>
            </NavLink>
          </Typography>

          {/*
            <SimpleMenu setStep={setStep} step={step} />
*/}
        </AppBar>
        {/*      <CookieConsent
          location="bottom"
          buttonText={t('cookie.decline')}
          cookieName="klangkarten"
          style={{
            '-webkit-transform': 'translate3d(0px, 0px, 0px)',
            background: 'rgba(255,255,255, 1)',
            color: '#00000',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            height: 220,
            padding: 12,
          }}
          buttonStyle={{
            color: '#fff',
            fontFamily: 'Inter',
            background: '#2d6258',
            fontSize: '16px',
            borderRadius: 5,
            height: 40,
            width: 120,
            marginLeft: 15,
            marginRight: 15,
            marginTop: 8,
            marginBottom: 90,
          }}
          declineButtonStyle={{
            color: '#fff',
            fontFamily: 'Inter',
            background: 'rgba(184,184,184,0.9)',
            fontSize: '16px',
            borderRadius: 5,
            height: 40,
            width: 120,
            marginLeft: 15,
            marginRight: 15,
            marginTop: 8,
            marginBottom: 90,
          }}
          expires={150}
          contentStyle={{ flex: '1', margin: 8 }}
          onAccept={() => {
            analytics();
          }}
          enableDeclineButton
          declineButtonText={t('cookie.decline')}
        >
          <span style={{ width: 200, color: '#000', fontFamily: 'Inter' }}>
            {t('cookie.description')} |
            <a
              href={dataPrivacy}
              target={'_blank'}
              style={{ color: '#a4a4a4', textDecorationStyle: 'none', textDecoration: 'none' }}
              rel={'noopener noreferrer'}
            >
              <>{t('cookie.data_privacy')}</>
            </a>
          </span>
        </CookieConsent>*/}

        <>
          <IfFirebaseAuthed>
            <Switch>
              <Route path="/p">
                <UrlAudioPlayer />
              </Route>

              <Route path="/legal-note">
                <LegalNote />
              </Route>

              <Route path="/scan">
                <QRScan />
              </Route>

              <Route path="/merrit_und_amrit">
                <Gaestebuch />
              </Route>
              {/*     <Route path="/list_all">
                <ListAll />
              </Route>*/}

              <Route path="/">
                <FirebaseAuthConsumer>
                  {user => (
                    <AudioRecordPage
                      auth={user}
                      setTopicId={setTopicId}
                      topicId={topicId}
                      setStep={setStep}
                      step={step}
                    />
                  )}
                </FirebaseAuthConsumer>
              </Route>
            </Switch>
          </IfFirebaseAuthed>
        </>
      </Router>
    </>
  );
}

export default App;

const useStyles = makeStyles(theme => ({
}));
