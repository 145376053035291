import { Route, Switch, useRouteMatch } from 'react-router-dom';
import React from 'react';
import PlayRemoteUrl from './PlayRemoteUrl';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

import ExamplePage from './ExamplePage';

const UrlAudioPlayer = () => {
  const { t, i18n } = useTranslation();
  let match = useRouteMatch();

  const classes = useStyles();

  return (
    <>
      <Switch>
        <Route path={`${match.path}/:audioUrl`}>
          <PlayRemoteUrl />
        </Route>
        <Route path={match.path}>
          <ExamplePage />
        </Route>
      </Switch>
    </>
  );
};

const useStyles = makeStyles(() => {
  return {
  }
});

export default UrlAudioPlayer;
