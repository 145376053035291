import { Box } from 'grommet';
import React from 'react';

const AppBar = props => (
  <Box
    style={{ position: 'fixed', width: '100%', height: 60, backgroundColor: '#fff', zIndex: 999 }}
    tag="header"
    direction="row"
    align="center"
    justify="between"
    pad={{ vertical: 'small', horizontal: 'medium' }}
    elevation={'xsmall'}
    {...props}
  />
);

export default AppBar;
