import { db } from '../firebase';

const dbService = db.collection('/records');

export const dbUtils = {
  getAll: () => {
    return dbService;
  },

  getBySoundId: async id => {
    //   return(dbService.doc().where('soundId', '==', 'fc2a57cf-cfda-4efb-a4b5-367e23769a11'))
    const snapshot = await db.collection('records').doc(id).get();
    //  console.log(snapshot)
    if (snapshot.empty) {
      // console.log('No matching documents.');
      return;
    }
    return snapshot.data();
    /*     snapshot.forEach((doc) => {
           // console.log(doc.id, ' => ', doc.data());
            return  doc.data()
        });*/
  },

  getTopicData: async topic => {
    const response=db.collection('records').where('topic', '==', topic);
      const data=await response.get();

/*    console.log(data.docs)
    data.docs.forEach(item=>{
      //  setBlogs([...blogs,item.data()])
      console.log(item.data())
    })*/

      return data.docs

  },

  create: (soundId, record) => {
    return dbService.doc(soundId).set(record);
  },

  update: (id, value) => {
    return dbService.doc(id).update(value);
  },

  deleteId: id => {
    return dbService.doc(id).delete();
  },
};
