import { Box } from 'grommet';
import React from 'react';

const LoadingIndicator = props => {
  return (
    <Box align={'center'} pad={'medium'}>
      <progress value={props.progress} max="100" />
      <br />
      <p>Audio-Daten werden vorbereitet</p>
    </Box>
  );
};

export default LoadingIndicator;
