import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';

const Page = props => {
  const classes = useStyles();

  return (
    <div className={classes.page} style={props.height ? { height: '100%', backgroundColor: props.color } : null}>
      {props.children}
    </div>
  );
};

export default Page;

const useStyles = makeStyles(theme =>
  createStyles({
    page: {
      paddingTop: 60,
      display: 'flex',
      flexDirection: 'column',
      //justifyContent: 'center',
      width: '100%',

      alignItems: 'center',
    },
  })
);
