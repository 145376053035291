import { Box, Clock } from 'grommet';
import RealTimeAudioCanvas from '../../../visuals/RealTimeAudioCanvas';
import React, { createRef, useEffect } from 'react';
import { IconButton, Typography } from '@material-ui/core';
import { MicOutlined, StopRounded } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { animationLooper3 } from '../../../utils/animationLooper3';
import { defaultColor } from '../../../utils/color';
import NoMicAccess from './NoMicAccess';
import { useTranslation } from 'react-i18next';

const RecordAudioVisual = props => {
  const { t, i18n } = useTranslation();

  const classes = useStyles();
  let canvas = createRef();
  useEffect(() => {
    animationLooper3.createCanvas(canvas.current, true, 400, 350);
    animationLooper3.draw(obj, 0, defaultColor, 0, '', '25px Caveat');
  }, []);

  //todo: only one canvas - refactor realtimeaudiocavas

  if (props.step === 2 && props.audio && !props.notAllowed) {
    return (
      <div style={{ marginTop: 10 }}>
        <RealTimeAudioCanvas audio={props.audio} audioContext={props.audioContextRecorder.current} />
        <Box align={'center'} pad={'medium'}>
          <Typography align={'center'} variant={'subtitle2'}>
            {t('record.stop')}
          </Typography>
          <Clock
            onChange={nextTime => props.checkTime(nextTime)}
            precision="seconds"
            type="digital"
            size="medium"
            run="backward"
            time={props.timer}
          />
          <IconButton className={classes.stop} onClick={() => props.onStop()}>
            <StopRounded style={{ color: '#fff' }} fontSize={'large'} />
          </IconButton>
        </Box>
      </div>
    );
  } else if (props.step === 2 && props.notAllowed) {
    return <NoMicAccess />;
  } else {
    return (
      <div style={{ marginTop: 10 }}>
        <canvas style={{ width: 'fit-content' }} ref={canvas} />
        <Box align={'center'}>
          <Box align={'center'} pad={'medium'}>
            <Typography align={'center'} variant={'subtitle2'}>
              {t('record.start')}
            </Typography>
          </Box>

          <IconButton className={classes.start} onClick={() => props.onStart()}>
            <MicOutlined style={{ color: '#fff' }} />
          </IconButton>
        </Box>
      </div>
    );
  }
};

export default RecordAudioVisual;
const useStyles = makeStyles(theme => {
  const buttonSize = 65;
  return {
    start: {
      width: buttonSize,
      height: buttonSize,
      position: 'absolute',
      bottom: '8%',
      backgroundColor: '#ac3a3d',
      '&:hover': { backgroundColor: '#AE7639' },
      boxShadow: '4px 4px 6px 0px #0003',

      [theme.breakpoints.down('sm')]: {
        bottom: '5%',
      },
    },
    stop: {
      width: buttonSize,
      height: buttonSize,
      position: 'absolute',
      bottom: '8%',
      // transform: `translate(-50%, -50%)`,
      backgroundColor: '#ac3a3d',
      '&:hover': { backgroundColor: '#AE7639' },
      boxShadow: '4px 4px 6px 0px #0003',
      [theme.breakpoints.down('sm')]: {
        bottom: '5%',
      },
    },
  };
});

const obj = {
  0: 129,
  1: 129,
  2: 129,
  3: 129,
  4: 129,
  5: 129,
  6: 129,
  7: 129,
  8: 129,
  9: 129,
  10: 129,
  11: 129,
  12: 129,
  13: 130,
  14: 130,
  15: 130,
  16: 130,
  17: 130,
  18: 130,
  19: 130,
  20: 130,
  21: 130,
  22: 130,
  23: 130,
  24: 130,
  25: 130,
  26: 130,
  27: 130,
  28: 130,
  29: 130,
  30: 130,
  31: 130,
  32: 130,
  33: 130,
  34: 130,
  35: 130,
  36: 130,
  37: 130,
  38: 129,
  39: 129,
  40: 129,
  41: 129,
  42: 129,
  43: 129,
  44: 129,
  45: 129,
  46: 129,
  47: 129,
  48: 129,
  49: 129,
  50: 129,
  51: 129,
  52: 129,
  53: 129,
  54: 129,
  55: 129,
  56: 128,
  57: 128,
  58: 128,
  59: 128,
  60: 128,
  61: 128,
  62: 128,
  63: 128,
  64: 128,
  65: 128,
  66: 128,
  67: 128,
  68: 128,
  69: 128,
  70: 128,
  71: 128,
  72: 128,
  73: 128,
  74: 128,
  75: 128,
  76: 127,
  77: 127,
  78: 127,
  79: 127,
  80: 127,
  81: 127,
  82: 127,
  83: 127,
  84: 127,
  85: 127,
  86: 127,
  87: 127,
  88: 127,
  89: 127,
  90: 127,
  91: 127,
  92: 127,
  93: 127,
  94: 127,
  95: 127,
  96: 127,
  97: 127,
  98: 127,
  99: 127,
  100: 127,
  101: 127,
  102: 127,
  103: 127,
  104: 127,
  105: 127,
  106: 127,
  107: 127,
  108: 127,
  109: 127,
  110: 127,
  111: 127,
  112: 127,
  113: 127,
  114: 127,
  115: 127,
  116: 127,
  117: 127,
  118: 127,
  119: 127,
  120: 127,
  121: 127,
  122: 127,
  123: 127,
  124: 127,
  125: 127,
  126: 127,
  127: 127,
  128: 127,
  129: 127,
  130: 127,
  131: 127,
  132: 127,
  133: 127,
  134: 127,
  135: 127,
  136: 127,
  137: 127,
  138: 127,
  139: 127,
  140: 127,
  141: 127,
  142: 127,
  143: 127,
  144: 127,
  145: 127,
  146: 128,
  147: 128,
  148: 128,
  149: 128,
  150: 128,
  151: 128,
  152: 128,
  153: 128,
  154: 128,
  155: 128,
  156: 128,
  157: 128,
  158: 128,
  159: 128,
  160: 128,
  161: 128,
  162: 128,
  163: 128,
  164: 128,
  165: 128,
  166: 128,
  167: 128,
  168: 129,
  169: 129,
  170: 129,
  171: 129,
  172: 129,
  173: 129,
  174: 129,
  175: 129,
  176: 129,
  177: 129,
  178: 129,
  179: 129,
  180: 129,
  181: 129,
  182: 129,
  183: 129,
  184: 129,
  185: 129,
  186: 129,
  187: 129,
  188: 129,
  189: 129,
  190: 129,
  191: 129,
  192: 129,
  193: 129,
  194: 129,
  195: 129,
  196: 129,
  197: 129,
  198: 129,
  199: 129,
  200: 129,
  201: 129,
  202: 129,
  203: 129,
  204: 129,
  205: 129,
  206: 129,
  207: 129,
  208: 129,
  209: 129,
  210: 129,
  211: 129,
  212: 129,
  213: 129,
  214: 129,
  215: 128,
  216: 128,
  217: 128,
  218: 128,
  219: 128,
  220: 128,
  221: 128,
  222: 128,
  223: 128,
  224: 128,
  225: 128,
  226: 128,
  227: 128,
  228: 128,
  229: 128,
  230: 128,
  231: 128,
  232: 128,
  233: 128,
  234: 128,
  235: 128,
  236: 128,
  237: 128,
  238: 128,
  239: 128,
  240: 128,
  241: 128,
  242: 128,
  243: 128,
  244: 128,
  245: 128,
  246: 128,
  247: 128,
  248: 128,
  249: 128,
  250: 127,
  251: 127,
  252: 127,
  253: 127,
  254: 127,
  255: 127,
  256: 127,
  257: 127,
  258: 127,
  259: 127,
  260: 127,
  261: 127,
  262: 127,
  263: 127,
  264: 127,
  265: 127,
  266: 127,
  267: 127,
  268: 127,
  269: 127,
  270: 127,
  271: 127,
  272: 127,
  273: 127,
  274: 127,
  275: 127,
  276: 127,
  277: 127,
  278: 127,
  279: 127,
  280: 127,
  281: 127,
  282: 127,
  283: 127,
  284: 127,
  285: 127,
  286: 126,
  287: 126,
  288: 126,
  289: 126,
  290: 126,
  291: 126,
  292: 126,
  293: 126,
  294: 126,
  295: 126,
  296: 126,
  297: 126,
  298: 126,
  299: 126,
  300: 126,
  301: 126,
  302: 126,
  303: 125,
  304: 125,
  305: 125,
  306: 125,
  307: 125,
  308: 125,
  309: 125,
  310: 125,
  311: 125,
  312: 125,
  313: 125,
  314: 125,
  315: 125,
  316: 125,
  317: 125,
  318: 125,
  319: 125,
  320: 125,
  321: 125,
  322: 125,
  323: 125,
  324: 125,
  325: 125,
  326: 125,
  327: 125,
  328: 125,
  329: 125,
  330: 125,
  331: 125,
  332: 125,
  333: 125,
  334: 125,
  335: 125,
  336: 125,
  337: 125,
  338: 125,
  339: 125,
  340: 125,
  341: 125,
  342: 126,
  343: 126,
  344: 126,
  345: 126,
  346: 126,
  347: 126,
  348: 126,
  349: 126,
  350: 126,
  351: 126,
  352: 126,
  353: 126,
  354: 126,
  355: 127,
  356: 127,
  357: 127,
  358: 127,
  359: 127,
  360: 127,
  361: 127,
  362: 127,
  363: 127,
  364: 127,
  365: 127,
  366: 127,
  367: 127,
  368: 127,
  369: 128,
  370: 128,
  371: 128,
  372: 128,
  373: 128,
  374: 128,
  375: 128,
  376: 128,
  377: 128,
  378: 128,
  379: 128,
  380: 128,
  381: 128,
  382: 128,
  383: 128,
  384: 128,
  385: 128,
  386: 128,
  387: 128,
  388: 128,
  389: 128,
  390: 128,
  391: 128,
  392: 128,
  393: 129,
  394: 129,
  395: 129,
  396: 129,
  397: 129,
  398: 129,
  399: 129,
  400: 129,
  401: 129,
  402: 129,
  403: 129,
  404: 129,
  405: 129,
  406: 129,
  407: 129,
  408: 129,
  409: 129,
  410: 129,
  411: 129,
  412: 129,
  413: 129,
  414: 129,
  415: 129,
  416: 129,
  417: 129,
  418: 129,
  419: 129,
  420: 129,
  421: 129,
  422: 129,
  423: 129,
  424: 129,
  425: 129,
  426: 129,
  427: 129,
  428: 129,
  429: 129,
  430: 129,
  431: 129,
  432: 129,
  433: 129,
  434: 129,
  435: 129,
  436: 129,
  437: 129,
  438: 129,
  439: 129,
  440: 129,
  441: 128,
  442: 128,
  443: 128,
  444: 128,
  445: 128,
  446: 128,
  447: 128,
  448: 128,
  449: 128,
  450: 128,
  451: 128,
  452: 128,
  453: 128,
  454: 128,
  455: 128,
  456: 128,
  457: 128,
  458: 128,
  459: 128,
  460: 128,
  461: 128,
  462: 128,
  463: 128,
  464: 128,
  465: 128,
  466: 128,
  467: 128,
  468: 128,
  469: 128,
  470: 128,
  471: 128,
  472: 128,
  473: 128,
  474: 128,
  475: 128,
  476: 127,
  477: 127,
  478: 127,
  479: 127,
  480: 127,
  481: 127,
  482: 127,
  483: 127,
  484: 127,
  485: 127,
  486: 127,
  487: 127,
  488: 127,
  489: 127,
  490: 127,
  491: 127,
  492: 127,
  493: 127,
  494: 127,
  495: 127,
  496: 127,
  497: 127,
  498: 127,
  499: 127,
  500: 127,
  501: 127,
  502: 127,
  503: 127,
  504: 127,
  505: 127,
  506: 127,
  507: 127,
  508: 127,
  509: 127,
  510: 127,
  511: 127,
  512: 127,
  513: 127,
  514: 127,
  515: 127,
  516: 128,
  517: 128,
  518: 128,
  519: 128,
  520: 128,
  521: 128,
  522: 128,
  523: 128,
  524: 128,
  525: 128,
  526: 128,
  527: 128,
  528: 128,
  529: 128,
  530: 128,
  531: 128,
  532: 128,
  533: 128,
  534: 128,
  535: 128,
  536: 128,
  537: 128,
  538: 128,
  539: 128,
  540: 128,
  541: 128,
  542: 128,
  543: 128,
  544: 128,
  545: 128,
  546: 128,
  547: 128,
  548: 128,
  549: 128,
  550: 128,
  551: 128,
  552: 128,
  553: 128,
  554: 128,
  555: 128,
  556: 128,
  557: 128,
  558: 128,
  559: 128,
  560: 128,
  561: 128,
  562: 127,
  563: 127,
  564: 127,
  565: 127,
  566: 127,
  567: 127,
  568: 127,
  569: 127,
  570: 127,
  571: 127,
  572: 127,
  573: 127,
  574: 127,
  575: 127,
  576: 127,
  577: 127,
  578: 127,
  579: 127,
  580: 127,
  581: 127,
  582: 127,
  583: 127,
  584: 127,
  585: 127,
  586: 127,
  587: 127,
  588: 127,
  589: 127,
  590: 127,
  591: 127,
  592: 127,
  593: 127,
  594: 127,
  595: 127,
  596: 127,
  597: 127,
  598: 127,
  599: 127,
  600: 127,
  601: 127,
  602: 127,
  603: 127,
  604: 127,
  605: 127,
  606: 127,
  607: 127,
  608: 127,
  609: 127,
  610: 127,
  611: 127,
  612: 127,
  613: 127,
  614: 127,
  615: 127,
  616: 127,
  617: 127,
  618: 127,
  619: 127,
  620: 127,
  621: 127,
  622: 127,
  623: 127,
  624: 127,
  625: 127,
  626: 127,
  627: 127,
  628: 127,
  629: 127,
  630: 127,
  631: 127,
  632: 127,
  633: 127,
  634: 127,
  635: 127,
  636: 127,
  637: 127,
  638: 127,
  639: 127,
  640: 127,
  641: 127,
  642: 127,
  643: 127,
  644: 127,
  645: 127,
  646: 127,
  647: 127,
  648: 127,
  649: 127,
  650: 127,
  651: 127,
  652: 127,
  653: 127,
  654: 127,
  655: 127,
  656: 127,
  657: 127,
  658: 127,
  659: 127,
  660: 127,
  661: 127,
  662: 127,
  663: 127,
  664: 127,
  665: 127,
  666: 127,
  667: 127,
  668: 127,
  669: 127,
  670: 127,
  671: 127,
  672: 127,
  673: 127,
  674: 127,
  675: 127,
  676: 127,
  677: 127,
  678: 127,
  679: 127,
  680: 127,
  681: 127,
  682: 127,
  683: 127,
  684: 127,
  685: 127,
  686: 127,
  687: 127,
  688: 127,
  689: 127,
  690: 127,
  691: 127,
  692: 127,
  693: 127,
  694: 127,
  695: 128,
  696: 128,
  697: 128,
  698: 128,
  699: 128,
  700: 128,
  701: 128,
  702: 128,
  703: 128,
  704: 128,
  705: 128,
  706: 128,
  707: 128,
  708: 128,
  709: 128,
  710: 129,
  711: 129,
  712: 129,
  713: 129,
  714: 129,
  715: 129,
  716: 129,
  717: 129,
  718: 129,
  719: 129,
  720: 129,
  721: 129,
  722: 129,
  723: 129,
  724: 129,
  725: 129,
  726: 129,
  727: 129,
  728: 129,
  729: 129,
  730: 129,
  731: 129,
  732: 129,
  733: 129,
  734: 129,
  735: 129,
  736: 129,
  737: 129,
  738: 129,
  739: 129,
  740: 129,
  741: 129,
  742: 129,
  743: 129,
  744: 129,
  745: 129,
  746: 129,
  747: 129,
  748: 129,
  749: 129,
  750: 129,
  751: 129,
  752: 129,
  753: 129,
  754: 129,
  755: 129,
  756: 129,
  757: 129,
  758: 129,
  759: 129,
  760: 128,
  761: 128,
  762: 128,
  763: 128,
  764: 128,
  765: 128,
  766: 128,
  767: 128,
  768: 128,
  769: 128,
  770: 128,
  771: 128,
  772: 128,
  773: 128,
  774: 128,
  775: 128,
  776: 128,
  777: 128,
  778: 128,
  779: 128,
  780: 127,
  781: 127,
  782: 127,
  783: 127,
  784: 127,
  785: 127,
  786: 127,
  787: 127,
  788: 127,
  789: 127,
  790: 127,
  791: 127,
  792: 127,
  793: 127,
  794: 127,
  795: 127,
  796: 127,
  797: 127,
  798: 127,
  799: 127,
  800: 127,
  801: 127,
  802: 127,
  803: 127,
  804: 127,
  805: 127,
  806: 127,
  807: 126,
  808: 126,
  809: 126,
  810: 126,
  811: 126,
  812: 126,
  813: 126,
  814: 126,
  815: 126,
  816: 126,
  817: 126,
  818: 126,
  819: 126,
  820: 126,
  821: 126,
  822: 126,
  823: 126,
  824: 126,
  825: 126,
  826: 126,
  827: 126,
  828: 126,
  829: 126,
  830: 126,
  831: 126,
  832: 126,
  833: 126,
  834: 126,
  835: 126,
  836: 126,
  837: 126,
  838: 126,
  839: 126,
  840: 126,
  841: 126,
  842: 126,
  843: 126,
  844: 126,
  845: 126,
  846: 126,
  847: 126,
  848: 126,
  849: 126,
  850: 127,
  851: 127,
  852: 127,
  853: 127,
  854: 127,
  855: 127,
  856: 127,
  857: 127,
  858: 127,
  859: 127,
  860: 127,
  861: 127,
  862: 127,
  863: 127,
  864: 127,
  865: 127,
  866: 127,
  867: 127,
  868: 127,
  869: 127,
  870: 128,
  871: 128,
  872: 128,
  873: 128,
  874: 128,
  875: 128,
  876: 128,
  877: 128,
  878: 128,
  879: 128,
  880: 128,
  881: 128,
  882: 128,
  883: 128,
  884: 128,
  885: 128,
  886: 129,
  887: 129,
  888: 129,
  889: 129,
  890: 129,
  891: 129,
  892: 129,
  893: 129,
  894: 129,
  895: 129,
  896: 129,
  897: 129,
  898: 129,
  899: 129,
  900: 129,
  901: 129,
  902: 129,
  903: 129,
  904: 129,
  905: 129,
  906: 129,
  907: 129,
  908: 129,
  909: 129,
  910: 129,
  911: 129,
  912: 129,
  913: 129,
  914: 129,
  915: 129,
  916: 129,
  917: 129,
  918: 129,
  919: 129,
  920: 129,
  921: 129,
  922: 129,
  923: 129,
  924: 129,
  925: 128,
  926: 128,
  927: 128,
  928: 128,
  929: 128,
  930: 128,
  931: 128,
  932: 128,
  933: 128,
  934: 128,
  935: 128,
  936: 128,
  937: 128,
  938: 128,
  939: 128,
  940: 128,
  941: 128,
  942: 128,
  943: 128,
  944: 128,
  945: 128,
  946: 128,
  947: 128,
  948: 128,
  949: 128,
  950: 128,
  951: 128,
  952: 128,
  953: 128,
  954: 128,
  955: 128,
  956: 128,
  957: 128,
  958: 128,
  959: 128,
  960: 128,
  961: 128,
  962: 128,
  963: 128,
  964: 128,
  965: 128,
  966: 128,
  967: 128,
  968: 128,
  969: 128,
  970: 128,
  971: 128,
  972: 127,
  973: 127,
  974: 128,
  975: 128,
  976: 128,
  977: 128,
  978: 127,
  979: 127,
  980: 127,
  981: 127,
  982: 127,
  983: 127,
  984: 127,
  985: 127,
  986: 127,
  987: 127,
  988: 127,
  989: 127,
  990: 127,
  991: 127,
  992: 127,
  993: 127,
  994: 127,
  995: 127,
  996: 127,
  997: 127,
  998: 127,
  999: 127,
  1000: 127,
  1001: 127,
  1002: 127,
  1003: 127,
  1004: 127,
  1005: 127,
  1006: 127,
  1007: 127,
  1008: 127,
  1009: 127,
  1010: 127,
  1011: 127,
  1012: 126,
  1013: 126,
  1014: 126,
  1015: 126,
  1016: 126,
  1017: 126,
  1018: 126,
  1019: 126,
  1020: 126,
  1021: 126,
  1022: 126,
  1023: 126,
};
