import React, { useEffect, useRef, useState } from 'react';
import { Button, IconButton, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import birthdaySound from '../../assets/backgroundSounds/HappyBirthday.mp3';
import japan from '../../assets/backgroundSounds/bl_japan.mp3';
import homecoming_christmas from '../../assets/backgroundSounds/xmas_60.mp3';
import diabetes from '../../assets/backgroundSounds/world_diabetes.mp3';
import silent from '../../assets/backgroundSounds/silent_60.mp3';
import trued from '../../assets/true-D_thanks-2020.jpg';
import roehl from '../../assets/christmas.jpg';
import dedoc from '../../assets/dedoc_xmas.jpg';

import { Link, useParams } from 'react-router-dom';
import { Howl } from 'howler';
import { animationLooper3 } from '../../utils/animationLooper3';
import { dbUtils } from '../../utils/dbService';
import { makeStyles } from '@material-ui/core/styles';
import { PlayArrow } from '@material-ui/icons';
import useWindowDimensions from '../../hooks/windowWidth';
import Page from '../common/Page';
import { useTranslation } from 'react-i18next';

const PlayRemoteUrl = () => {
  const { t, i18n } = useTranslation();
  let frequency_array = useRef([]);
  let rotate = useRef(0);
  let ref = useRef();
  const [isPlaying, setIsPlaying] = useState(false);
  const [isChrome, setIsChrome] = useState();
  const windowDimensions = useWindowDimensions();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    setIsChrome(navigator.userAgent.indexOf('Chrome') > -1);
  }, []);

  // let audioBackgroundSound = new Audio(backgroundSound)
  // audioBackgroundSound.preload = "auto"
  var analyserHowl = useRef();
  const requestRef = useRef(null);
  let { audioUrl } = useParams();
  const [played, setPlayed] = useState(false);

  const backgroundNoise = useRef();
  const sound = useRef();
  //const backgroundVolume = 0.7;
  const [backgroundVolume, setBackgroundVolume] = useState(0.7);

  const [color, setColor] = useState();
  const [svgImage, setSvgImage] = useState();
  const [text, setText] = useState();
  const [font, setFont] = useState('25px Caveat');
  const [bgMusic, setBgMusic] = useState();
  const classes = useStyles();
  const [topic, setTopic] = useState();
  const [picture, setPicture] = useState();
  const [userId, setUserId] = useState();
  const { height, width } = useWindowDimensions();
  const [proUrl, setProUrl] = useState(null);
  const [proMail, setProMail] = useState(null);

  useEffect(() => {
    const width = windowDimensions.width > 500 ? windowDimensions.width : 350;
    animationLooper3.createCanvas(ref.current, false, 400, width);

    dbUtils.getBySoundId(audioUrl).then(data => {
      console.log(data);
      animationLooper3.draw(frequency_array, 0, data.color, data.svgImage, data.text, data.font);
      cancelAnimationFrame(requestRef.current);
      const soundUrl = `https://firebasestorage.googleapis.com/v0/b/voicecards-691cb.appspot.com/o/voices%2F${data.userId}%2Fvoicecard_${audioUrl}_output.mp3?alt=media`;
      console.log(data.picturePath);
      setColor(data.color);
      setText(data.text);
      setSvgImage(data.svgImage);
      setBgMusic(data.backgroundSound);
      setTopic(data.topic);
      setFont(data.font ? data.font : '25px Caveat');
      setPicture(data.picturePath ? data.picturePath : null);
      setUserId(data.userId);
      setProUrl(data.proUrl);
      setProMail(data.proMail);
      let bgSound;
      if (data.topic === 'bday') {
        bgSound = birthdaySound;
      } else if (data.topic === 'wdd') {
        bgSound = diabetes;
      } else if (data.topic === 'xmas') {
        bgSound = homecoming_christmas;
      } else if (data.topic === 'homecoming_christmas') {
        bgSound = homecoming_christmas;
      } else if (data.topic === 'bsl') {
        bgSound = silent;
        //setBackgroundVolume(prevState => 0)
      } else if (data.topic === 'go_sake') {
        bgSound = japan;
        //setBackgroundVolume(prevState => 0)
      } else if (data.topic === 'dcb' || data.topic === 'cXD-45') {
        bgSound = silent;
        //setBackgroundVolume(prevState => 0)
      } else {
        bgSound = homecoming_christmas;
      }

      sound.current = new Howl({
        src: soundUrl,
        onend: function () {
          cancelAnimationFrame(requestRef.current);
          setIsPlaying(false);
          rotate.current = 0;
        },
      });

      sound.current.on('play', function () {
        var fadeouttime = 1000;
        setTimeout(function () {
          backgroundNoise.current.fade(backgroundVolume, 0, fadeouttime);
        }, (sound.current.duration() - sound.current.seek()) * 1000 - fadeouttime);
      });

      backgroundNoise.current = new Howl({
        src: bgSound,
        html5: true,
        onend: function () {
          setIsPlaying(false);
          setPlayed(true);
        },
      });

      backgroundNoise.current.on('fade', function () {
        backgroundNoise.current.stop();

        setPlayed(true);
      });
    });
  }, [audioUrl]);

  function howler() {
    rotate.current = 0;
    setIsPlaying(true);

    const hwl = window.hwl;
    analyserHowl.current = hwl.ctx.createAnalyser();
    hwl.masterGain.connect(analyserHowl.current);

    frequency_array = new Uint8Array(analyserHowl.current.frequencyBinCount);
    sound.current.play();

    backgroundNoise.current.volume(backgroundVolume);
    backgroundNoise.current.play();

    tick();
  }

  //todo: pause function
  function pause() {}

  useEffect(() => {
    return () => {
      cancelAnimationFrame(requestRef.current);
    };
  }, []);

  const tick = () => {
    animationLooper3.draw(frequency_array, rotate.current, color, svgImage, text, font);
    analyserHowl.current.getByteTimeDomainData(frequency_array);
    requestRef.current = requestAnimationFrame(tick);
    rotate.current += (2 * Math.PI) / 180;
  };

  return (
    <Page color={!sm ? color : '#fff'} height={true}>
      <div className={classes.spacing}></div>
      <canvas id="downloadCanvas" ref={ref} />

      {!isPlaying ? (
        <>
          <IconButton className={classes.play} onClick={() => howler()}>
            <PlayArrow className={classes.playIcon} fontSize={'large'} />
          </IconButton>

          {!played && <Typography className={classes.instruction}> Drücke um die Klangkarte abzuspielen</Typography>}
        </>
      ) : audioUrl === 'true_d_christmas_2020' ? (
        <img alt={'Group of people sitting on the ground'} src={trued} />
      ) : audioUrl === 'roehlfinanzen2020' ? (
        <img alt={'Roehl Finanzen'} src={roehl} />
      ) : topic === 'dedoc' ? (
        <img alt={'dedoc - merry xmas'} src={dedoc} />
      ) : picture ? (
        <img
          alt={''}
          className={classes.photo}
          style={isChrome ? { 'image-rendering': '-webkit-optimize-contrast' } : null}
          src={`https://firebasestorage.googleapis.com/v0/b/voicecards-691cb.appspot.com/o/images%2F${userId}%2Fpicture_${audioUrl}.png?alt=media`}
        />
      ) : null}

      {played ? (
        <>
          {topic === 'dcb' && (
            <a className={classes.link} href={'https://www.dcberne.com/'} target={'_blank'} rel="noopener noreferrer">
              {t('play_page.website_dcb')}
            </a>
          )}

          <Typography className={classes.instruction}>{t('play_page.design_own')}</Typography>
          <Button style={{ marginTop: 20 }} component={Link} to={'/'}>
            {t('play_page.button')}
          </Button>
          <div className={classes.buttonGroup}>
            {proUrl && (
              <a
                className={classes.link}
                style={{ padding: 4 }}
                href={'//' + proUrl}
                target={'_blank'}
                rel="nofollow noopener noreferrer"
              >
                {proUrl}
              </a>
            )}
            {proMail && (
              <a
                style={{ padding: 4 }}
                className={classes.link}
                href={`mailto:${proMail}?subject=Danke%20für%20die%20Grüße`}
                target={'_blank'}
                rel="nofollow noopener noreferrer"
              >
                {proMail}
              </a>
            )}
          </div>
        </>
      ) : (
        <div style={{ fontFamily: 'Caveat', color: color }}>
          -<div style={{ fontFamily: 'Calibri Light', color: color }}>-</div>
        </div>
      )}
    </Page>
  );
};

const useStyles = makeStyles(theme => {
  const buttonSize = 65;
  return {
    buttonGroup: {
      display: 'flex',
      flexDirection: 'row',

      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    button: {
      margin: theme.spacing(1),
      marginTop: 10,
      backgroundColor: '#fff',
      color: 'rgba(9,131,102,1)',
      boxShadow: '3px 3px 4px 0px #fDD1',
      [theme.breakpoints.down('sm')]: {
        marginTop: 10,
        backgroundColor: 'transparent',
        color: 'rgba(9,131,102,1)',
      },
    },
    spacing: {
      [theme.breakpoints.down('sm')]: {
        marginTop: 10,
      },
    },
    photo: {
      borderRadius: 5,
      width: 450,
      [theme.breakpoints.down('sm')]: {
        width: 350,
        borderRadius: 12,

        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
      },
    },
    play: {
      width: buttonSize,
      height: buttonSize,
      alignSelf: 'center',
      margin: theme.spacing(2),
      position: 'relative',
      //left: buttonSize / 2,
      // transform: `translate(-50%, -50%)`,
      boxShadow: '4px 4px 6px 0px #0003',
      backgroundColor: '#fbfbfb',
      [theme.breakpoints.down('sm')]: {
        backgroundColor: '#28413C',
      },
    },
    playIcon: {
      color: '#28413C',
      [theme.breakpoints.down('sm')]: {
        color: '#fbfbfb',
      },
    },
    stop: {
      width: buttonSize,
      height: buttonSize,
      alignSelf: 'center',
      position: 'relative',
      left: buttonSize / 2,
      transform: `translate(-50%, -50%)`,
      backgroundColor: '#4d80d7',
      boxShadow: '4px 4px 6px 0px #0003',
    },
    instruction: {
      color: '#fff',
      [theme.breakpoints.down('sm')]: {
        color: '#000',
      },
    },
    link: {
      color: '#fff',
      marginTop: 10,
      marginBottom: 18,
      '&:hover': {
        color: '#4bac28',
      },
      '&:active': {
        color: '#29750e',
      },

      [theme.breakpoints.down('sm')]: {
        color: '#000',
      },
    },
  };
});

export default PlayRemoteUrl;
