import React, { useState } from 'react';
import QrReader from 'react-qr-reader';
import { useHistory } from 'react-router-dom';
import Page from '../common/Page';

const QRScan = () => {
  let history = useHistory();

  const [result, setResult] = useState(null);

  const handleScan = data => {
    if (data) {
      setResult(data);
      const getStringFromData = data.toString();
      const includesKlangkarten = getStringFromData.includes('https://klangkarten.de/p/');
      if (includesKlangkarten) {
        //  history.push(data);
        window.open(data, '_self');
      }
    }
  };
  const handleError = err => {
    console.error(err);
  };

  return (
    <Page>
      <QrReader delay={300} onError={handleError} onScan={handleScan} style={{ width: '100%' }} />
      {result && (
        <a href={result} target={'_blank'} rel={'noopener'}>
          {result}
        </a>
      )}
    </Page>
  );
};

export default QRScan;
