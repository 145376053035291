export const defaultColors = [
  '#578AD6',
  '#003F7D',
  '#c141b5',
  '#FF8E00',
  '#BF352F',
  '#8507be',
  '#2f2f2f',
  '#88A34F',
  '#005B55',
  '#d2c01f',
];

export const christmasColors = [
  '#002447',
  '#003F7D',
  '#0693E3',
  '#FF8E00',
  '#BF352F',
  '#8C0813',
  '#59070E',
  '#88A34F',
  '#005B55',
  '#28413C',
];
export const dedocColors = [
  '#002447',
  '#003F7D',
  '#7993c6',
  '#0d4a8d',
  '#902408',
  '#8C0813',
  '#59070E',
  '#88A34F',
  '#005B55',
  '#158C74',
];
export const weddingColors = [
  '#002447',
  '#003F7D',
  '#7993c6',
  '#e0dfce',
  '#c8852d',
  '#edb800',
  '#d3a3b4',
  '#777777',
  '#88A34F',
  '#005B55',

];
export const diabetesColors = [
  '#578AD6',
  '#003F7D',
  '#0693E3',
  '#FF8E00',
  '#BF352F',
  '#8C0813',
  '#59070E',
  '#88A34F',
  '#005B55',
  '#158C74',
];
export const birthdayColors = [
  '#002447',
  '#003F7D',
  '#0693E3',
  '#FF8E00',
  '#BF352F',
  '#5c03d7',
  '#44cb24',
  '#88A34F',
  '#005B55',
  '#158C74',
];
