import imageUpload from '../../pages/AudioRecordPage/Customizer/imageUpload';
import shortUUID from 'short-uuid';

export function download(canvasId, id, userId) {
  var canvas = document.getElementById(canvasId);
  const formatDate = new Date().toLocaleDateString();
  const uuid = shortUUID.generate();
  canvas.toBlob(function (blob) {
    var image = new Image();
    image.src = blob;

    imageUpload(blob, id, userId);
  });

  /*  var img = new Image();    // move this into the onload of the image to make sure the canvas has been drawn
  img.src = canvas.toDataURL("image/png");
  document.body.appendChild(img);*/

  var url = canvas.toDataURL({ pixelRatio: 3 });

  var link = document.createElement('a');
  link.download = `klangkarte_${uuid}_${formatDate}.png`;
  link.href = document.getElementById(canvasId).value = url;
  link.click();
}
