import React from 'react';
import { createStyles, Grid, makeStyles } from '@material-ui/core';
import Page from '../common/Page';
import ExampleKlangKarte from './ExampleKlangKarte';
import happy_birthday from '../../assets/images/example_cards/happy_birthday_example.png';
import trueD_image from '../../assets/images/example_cards/trueD.png';
import { useTranslation } from 'react-i18next';

const ExamplePage = () => {
  const { t, i18n } = useTranslation();

  const classes = useStyles();
  const happy_birthday_id = 'jTTUA2w4TNypZADfkzgRz3';
  const trueD_id = 'true_d_christmas_2020';

  return (
    <Page>
      <h1>{t('examples.example')}</h1>
      <Grid className={classes.container} container spacing={1}>
        <ExampleKlangKarte image={happy_birthday} id={happy_birthday_id} />
        <ExampleKlangKarte image={trueD_image} id={trueD_id} />
      </Grid>
    </Page>
  );
};

export default ExamplePage;

const useStyles = makeStyles((theme) =>
    createStyles({
      container:{justifyContent:'center'}
    })
);
