import React from 'react';
import { Button, Fab, Typography } from '@material-ui/core';
import Snowfall from 'react-snowfall';
import { Link } from 'react-router-dom';

import ListItem from '../../common/ListItem';
import { makeStyles } from '@material-ui/core/styles';
import iphone from '../../../assets/images/klangkarte_logo_example.png';
import { BrushOutlined, EmailOutlined, Mic, MicOutlined, PhoneOutlined, SendOutlined } from '@material-ui/icons';
import kevin from '../../../assets/images/kevin_röhl_klangkarten.jpg';
import wiebke from '../../../assets/images/wiebke.jpg';
import dataPrivacy from '../../../assets/data-privacy.pdf';
import Page from '../../common/Page';
import LottieAutoPlay from './Animations/LottieAutoPlay';
import { useTranslation } from 'react-i18next';
import snow from '../../../assets/snow3.svg';
import snowSmall from '../../../assets/small.svg';
import snowLarge from '../../../assets/large.svg';
import footer from '../../../assets/footer.svg';

const StartRecord = props => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();

  const emailBody =
    'Hey klangkarten Team, können wir einen Termin vereinbaren, um über die Gestaltung einer eigenen klangkarte zu besprechen?';

  return (
    <Page>
      <div className={classes.example}>
        <Snowfall
          color={'#fff'}
          snowflakeCount={10}
          radius={[0.5, 2]}
          speed={[0.2, 0.6]}
          //wind={settings.wind}
          style={{ zIndex: 999, width: '100%', height: 540, position: 'absolute', top: 'unset' }}
        />
        <LottieAutoPlay />
      </div>

      <div className={classes.header}>
        <Typography className={classes.headline} variant={'h1'}>
          {t('start.header_title')}
        </Typography>
        <Typography className={classes.subtitle} variant={'h5'}>
          {t('start.header_description')}
        </Typography>
        <div className={classes.headerButtonGroup}>
          {/*     <Button component={Link} to={'/p'} className={classes.recordButton}>
            <GraphicEqOutlined fontSize={'inherit'} style={{ paddingRight: 8, fontSize: 30 }} />
            {t('menu.examples')}
          </Button>*/}
          <Button onClick={() => props.setStep(1)} className={classes.recordButton}>
            <MicOutlined fontSize={'inherit'} style={{ paddingRight: 8, fontSize: 30 }} />
            {t('start.start_record')}
          </Button>
        </div>
      </div>
      <div className={classes.snow} />

      <Typography align={'center'} className={classes.h2} variant={'h2'}>
        {t('start.how_it_works')}
      </Typography>
      <ul className={classes.list}>
        <ListItem
          icon={<MicOutlined style={{ color: '#fff' }} />}
          title={t('start.record_title')}
          description={t('start.record_description')}
        />
        <ListItem
          icon={<BrushOutlined style={{ color: '#fff' }} />}
          title={t('start.design_title')}
          description={t('start.design_description')}
        />
        <ListItem
          icon={<SendOutlined style={{ color: '#fff' }} />}
          title={t('start.send_title')}
          description={t('start.send_description')}
        />
      </ul>
      <div className={classes.contactContainer}>
        <div>
          <ListItem
            title={t('start.custom_logo_title')}
            description={t('start.custom_logo_description')}
            center={true}
          />
          <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
            <div style={{ marginBottom: 10 }}>
              <img
                style={{
                  borderRadius: '50%',
                  width: 120,
                  height: 120,
                  display: 'block',
                  marginBottom: 10,
                }}
                src={wiebke}
                alt={'Wiebke'}
              />
              <Typography align={'center'}>Wiebke</Typography>
            </div>
            <div style={{ marginBottom: 10 }}>
              <img
                style={{
                  borderRadius: '50%',
                  width: 120,
                  height: 120,
                  display: 'block',

                  marginBottom: 10,
                }}
                src={kevin}
                alt={'Kevin Röhl'}
              />
              <Typography align={'center'}>Kevin</Typography>
            </div>
          </div>

          <div className={classes.buttonGroup}>
            <Button
              href={'mailto:kr@lumind-solutions.com?subject=Eigene%20Klangkarte%20gestalten%20&body=' + emailBody}
              className={classes.button}
            >
              <EmailOutlined style={{ paddingRight: 8 }} />
              {t('start.send_email')}
            </Button>
            <Button className={classes.button} href={'tel:4917691343498'}>
              <PhoneOutlined style={{ paddingRight: 8 }} />
              {t('start.call')}
            </Button>
          </div>
        </div>
        <div className={classes.iphoneContainer}>
          <img src={iphone} className={classes.iphone} />
        </div>
      </div>
      <div className={classes.footerContainer}>
        <ul className={classes.footer}>
          <li>
            <Link href={dataPrivacy} target={'_blank'} rel="noopener">
              <Typography variant={'subtitle2'}> {t('start.data_privacy')}</Typography>
            </Link>
          </li>
          <li>
            <Link to={'/legal-note'}>
              <Typography variant={'subtitle2'}> {t('start.legal_note')}</Typography>
            </Link>
          </li>
          <li>
            <Link href={'https://www.instagram.com/klangkarten/'} target={'_blank'} rel="noopener">
              <Typography variant={'subtitle2'}>Instagram</Typography>
            </Link>
          </li>
        </ul>
        <Typography variant={'subtitle2'}>© klangkarten.de </Typography>
      </div>
      <Fab onClick={() => props.setStep(1)} variant="extended" size="large" color="primary" className={classes.fab}>
        <Mic />
        {t('start.start_record')}
      </Fab>
    </Page>
  );
};

export default StartRecord;

const useStyles = makeStyles(theme => ({
  fab: {
    zIndex: 999,
    position: 'fixed',
    '-webkit-transform': 'translate3d(0px, 0px, 0px)',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    fontFamily: 'Inter',
    fontVariantCaps: 'normal',
    textTransform: 'none',

    backgroundColor: '#24695b',
    '&:hover': {
      backgroundColor: '#0c9f7c',
    },
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    flexDirection: 'column-reverse',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  recordButton: {
    // marginTop: 8,
    marginBottom: 18,
    fontSize: 18,
    margin: theme.spacing(0, 1, 3, 1),
    width: 280,
    color: '#28413C',
    backgroundColor: '#fff',
    boxShadow: '3px 3px 4px 0px #0001',
    '&:hover': {
      backgroundColor: '#fff',
      boxShadow: '4px 4px 6px 0px #0003',
      color: '#0b944f',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  iphoneContainer: {
    marginBottom: 0,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 30,
    },
  },
  snow: {
    backgroundPositionX: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    width: '100%',
    backgroundImage: `url(${snow})`,
    height: 83,
    [theme.breakpoints.down('sm')]: {
      backgroundImage: `url(${snowSmall})`,
      height: 83,

    },
    [theme.breakpoints.up('lg')]: {
      backgroundImage: `url(${snowLarge})`,
      height: 160,

    },
  },
  iphone: {
    [theme.breakpoints.down('sm')]: {
      width: 240,
    },
  },
  contactContainer: {
    backgroundPositionX: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundImage: `url(${footer})`,


    // height: 660,

    display: 'flex',
    width: '100%',
    //padding: theme.spacing(3),
    alignItems: 'center',
    justifyContent: 'space-evenly',
    flexDirection: 'column-reverse',
    marginTop: 30,
    paddingTop: 12,
    //marginBottom: 30,
    backgroundColor: '#fff',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  headerButtonGroup: { flexDirection: 'row', display: 'flex' },
  buttonGroup: { flexDirection: 'column', display: 'flex', alignItems: 'center' },
  headline: {
    fontFamily: 'Inter',
    fontWeight: '600',
    fontSize: '2.7em',
    width: 650,
    textAlign: 'center',
    color: '#35414B',
    padding: theme.spacing(0, 2, 1, 2),
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.2em',
      width: 325,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '3.5em',
      width: 750,
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'rgba(219,208,199,0.7)',
    width: '100%',
    padding: theme.spacing(4),
  },
  subtitle: {
    fontFamily: 'Inter',
    fontWeight: 'normal',
    fontSize: '1.2em',
    color: '#4E5A65',
    textAlign: 'center',
    padding: theme.spacing(2, 2, 3.5, 2),
    maxWidth: 700,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1.5, 1.5),
      fontSize: '1.2em',
      maxWidth: 400,
    },
  },

  subtitle2: {
    paddingTop: 60,
    color: '#35414B',
    fontFamily: 'Inter',
    fontWeight: '500',
    fontSize: '1.2em',
  },
  h2: {
    padding: theme.spacing(5, 2, 4, 0),
    color: '#35414B',
    fontFamily: 'Inter',
    fontWeight: '500',
    fontSize: '1.8em',
  },
  list: {
    display: 'flex',
    flexDirection: 'row',
    color: '#4E5A65',
    listStyle: 'none',
    textAlign: 'center',
    padding: 0,
    margin: 0,
    maxWidth: 900,
    [theme.breakpoints.up('lg')]: {
      maxWidth: 1400,

    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: 350,
      flexDirection: 'column',
    },
  },
  example: {
    width: '100%',
    height: 480,
    backgroundColor: '#28413C',
    marginTop: -20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      height: 440,
    },
    [theme.breakpoints.down('sm')]: {
      height: 350,
    },
  },
  button: {
    margin: theme.spacing(1),
    padding: theme.spacing(1.5, 1),
    minWidth: 300,
    color: '#fff',
    '&:hover': {
      color: '#fff',
    },
    fontFamily: 'Inter',
    fontVariantCaps: 'normal',
    textTransform: 'none',
  },
  footerContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'flex-start',
    padding: theme.spacing(4, 2, 4, 2),
    backgroundColor: '#fff',
    width: '100%',
  },
  footer: {
    color: '#4E5A65',
    listStyle: 'none',
    textAlign: 'left',
    padding: theme.spacing(2),
    margin: 0,
  },
  head: {
    height: 250,
    [theme.breakpoints.down('sm')]: {
      height: 220,
    },
  },
}));
