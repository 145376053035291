import React from 'react';
import ReactDOM from 'react-dom';
import { firebaseConfig } from './firebase';
import firebase from 'firebase';
import 'firebase/auth';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'fontsource-caveat';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Howler } from 'howler';
import { FirebaseAuthProvider } from '@react-firebase/auth';
import { FirestoreProvider } from '@react-firebase/firestore';
import './index.css';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import './i18next';

/*try{
if (!window.MediaRecorder) {
    window.MediaRecorder = AudioRecorder

}
}catch(e){alert("Kein AudioRecorder verfügbar")}*/

const muiTheme = createMuiTheme({
  overrides: {
    MuiAccordionSummary:{
      root: {
        height: 40,
        minHeight: 40
      },
},
    MuiAccordion: {
      root: {
        "&$expanded": {
          margin: 0
        },
      },
      },
    MuiIconButton:{
      root:{
        '&:hover': {
          backgroundColor: '#2d6258',
        },
      },
    },
    MuiButton: {
      text: {
        paddingTop: 12,
        paddingBottom: 12,
      },

      root: {
        maxWidth: 300,
        minWidth: 180,
        fontVariantCaps: 'normal',
        textTransform: 'none',
        backgroundColor: '#28413C',
        color: '#fff',
        '&:hover': {
          color: '#fff',
          backgroundColor: '#2d6258',
        },
        '@media (hover: none)': {
          '&:hover': {
            backgroundColor: '#2d6258 !important',
          },
        },
      },
    },
  },

  typography: {
    fontFamily: ['Inter'].join(','),
  },
  palette: {
    primary: {
      main: '#28413C',
    },
    secondary: {
      main: '#101E40',
    },
  },
});

const theme = {
  global: {
    colors: {
      focus: 'none',
      'light-2': '#f5f5f5',
      text: {
       // light: 'rgba(0, 0, 0, 0.87)',
      },
      primary: '#303f9f',
    },
    edgeSize: {
      small: '14px',
    },
    elevation: {
      light: {
        medium:
          '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)',
      },
    },
    font: {
      family: 'Inter',
      size: '14px',
      height: '20px',
    },
  },
};

Howler.mute(false);
Howler.volume(1);
window.hwl = Howler;

ReactDOM.render(
  <React.StrictMode>
    <FirebaseAuthProvider firebase={firebase} {...firebaseConfig}>
      <FirestoreProvider firebase={firebase} {...firebaseConfig}>
        <MuiThemeProvider theme={muiTheme}>
            <CssBaseline />
            <App />
        </MuiThemeProvider>
      </FirestoreProvider>
    </FirebaseAuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
