import React from 'react';

import { Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const MailTo = props => {
  const { t, i18n } = useTranslation();

  const emailBody = encodeURI(t('style_page.email_body'));
  const classes = useStyles();
  const { variant } = props;
  console.log(variant);
  return (
    <div className={classes.pro}>
      <Typography style={{ padding: 10 }} variant={variant || 'body1'}>
        {props.text}
      </Typography>
      <div>
        <Button
          style={{}}
          className={classes.email}
          component={'a'}
          target={'_blank'}
          href={'mailto:kr@lumind-solutions.com?subject=Eigene%20Klangkarte%20gestalten%20&body=' + emailBody}
        >
          {t('style_page.send_email')}
        </Button>
      </div>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  email: {
    marginBottom: 20,
  },
  pro: {
    margin: theme.spacing(3),
    width: 300,
    textAlign: 'left',
    // backgroundColor: '#434548',
    justifyContent: 'flex-start',
    display: 'flex',
    color: '#2a2a2a',
    flexDirection: 'column',
    borderRadius: 5,
    [theme.breakpoints.down('sm')]: {},
  },
}));

export default MailTo;
