import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { dbUtils } from './dbService';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

function SimpleDialog(props) {
  const { t, i18n } = useTranslation();

  const { onClose, selectedValue, open } = props;
  const handleClose = isDelete => {
    if (isDelete) {
      dbUtils.deleteId(props.uuid);
      props.setStep(1);
      // props.onStart();
    }
    onClose(selectedValue);
  };

  return (
    <Dialog open={open} onClose={() => handleClose(false)}>
      <DialogTitle>{t('style_page.dialog.title')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t('style_page.dialog.description')}</DialogContentText>
      </DialogContent>
      <div
        style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center !important', alignItems: 'center' }}
      >
        <Button style={{ backgroundColor: '#101E40', marginBottom: 8 }} autoFocus onClick={() => handleClose(false)}>
          {t('style_page.dialog.cancel')}
        </Button>
        <Button style={{ backgroundColor: '#933D38', marginBottom: 12 }} onClick={() => handleClose(true)} autoFocus>
          {t('style_page.dialog.delete_next')}
        </Button>
      </div>
    </Dialog>
  );
}

function DeleteDialog(props) {
  const { t, i18n } = useTranslation();

  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  const handleClickOpen = () => {
    if (!props.shared) {
      setOpen(true);
    } else {
      //props.onStart();
      props.setStep(1);
    }
  };

  const handleClose = value => {
    setOpen(false);
  };

  return (
    <div>
      <Button disabled={props.isPlaying} className={classes.new} onClick={() => handleClickOpen()}>
        {t('style_page.new_record')}
      </Button>
      <SimpleDialog
        uuid={props.uuid}
        setStep={props.setStep}
        onStart={props.onStart}
        open={open}
        onClose={handleClose}
      />
    </div>
  );
}

export default DeleteDialog;
const useStyles = makeStyles(theme => {
  return {
    new: {
      backgroundColor: 'rgba(255,255,255,0.6)',
      color: '#2d6258',
      '&:hover': { backgroundColor: 'rgba(255,255,255,0.8)', color: '#2f8475' },
      '@media (hover: none)': {
        '&:hover': {
          backgroundColor: 'transparent !important',
        },
      },
    },
  };
});
