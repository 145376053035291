import React, { useEffect, useRef, useState } from 'react';
import { createStyles, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import Lottie from 'lottie-react';
import treeAnimation from './tee_lottie_2.json';

const LottieAutoPlay = props => {
  const classes = useStyles();
  const theme = useTheme();
  const down = useMediaQuery(theme.breakpoints.down('sm'));
  const [scrollPosition, setScrollPosition] = useState(0);
  const lottieRef = useRef();

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
    if (position > 0) {
      lottieRef.current.play();
    }
    if (position < 0 || position > 315) {
      lottieRef.current.pause();
    }
  };

  useEffect(() => {
    lottieRef.current.pause();

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  let direction = 1;
  let width = down ? 375 : 550;
  return (
    <Lottie
      lottieRef={lottieRef}
      width={width}
      style={{ width: width }}
      onComplete={() => {
        direction = direction * -1;
        lottieRef.current.setDirection(direction);
        lottieRef.current.play();
      }}
      // height={340}
      animationData={treeAnimation}
      autoPlay={false}
      loop={false}
    />
  );
};

export default LottieAutoPlay;

const useStyles = makeStyles(theme =>
  createStyles({
    container: {},
  })
);
